.content-search-result.dummy:before {
    content: "";
    position: absolute;
    z-index: 1;
    background-image: linear-gradient(180deg, rgba(239, 250, 255, 0.6), rgba(239, 250, 255, 0.9));
    top: -10px;
    left: -10px;
    right: -40px;
    bottom: -10px;
    // width:100%;
    // height:100%;
}

.hotel-result-box {
    margin-bottom: 20px; // padding: 16px 22px;
    transition: all 0.2s;
    -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    border-radius: 8px !important;
    background: white;
    overflow: hidden;
    display: flex;

    &:hover {
        -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
    }

    .HotelTags {
        margin-left: 10px;
    }

    .carousel .control-dots {
        bottom: 0;
        top: 0 !important;
        height: 25px !important;
    }

    .carousel.carousel-slider .control-arrow {
        top: 0;
        color: #fff;
        font-size: 26px;
        bottom: 0;
        margin-top: 0;
        padding: 15px;
    }

    .hotel-result-box__slider {
        overflow: hidden;
        flex-grow: 1;
        position: relative;
        border-right: 1px solid #d8d8d8;

        .carousel-root {
            height: 100%;
        }

        .hotel-result-box__slider-image {
            width: 100%;
            min-height: 500px;
            height: 100%;
            background-size: cover;
            background-position: center center;
            cursor: pointer;
        }

        .hotel-result-box__slider-content {
            background: linear-gradient(157.58deg, rgba(0, 0, 0, 0.65) 24.08%, rgba(196, 196, 196, 0) 49.19%);
            background: linear-gradient(3deg, rgba(0, 0, 0, 0.65) 33.08%, rgba(196, 196, 196, 0) 69.19%);
            position: absolute;
            bottom: 0;
            width: 100%;
            color: white;
            padding: 140px 30px 20px;
            cursor: pointer;

            h2 {
                color: white;
                font-weight: 700;
                font-size: 28px;
                margin-bottom: 10px;
            }
            p {
                font-weight: 300;
                margin-bottom: 0px;

                svg {
                    margin-right: 8px;
                }
            }

            .hotel-result-box__slider-details {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                align-items: center;
            }
        }
    }

    .hotel-result-box__content {
        cursor: pointer;
        width: 270px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        color: #757787;
        margin: 0;
        padding: 30px;

        .hotel-result-box__logo {
            margin-bottom: 20px;
            img {
                max-width: 185px;
            }
        }

        .hotel-result-box__stars {
            color: $primary;
            margin-bottom: 20px;
            // margin-top: 20px;
        }

        p {
            margin-bottom: 5px;
        }

        // >div  {
        //     padding: 10px 20px;

        //     > div {
        //         margin-bottom: 20px;
        //     }
        // }

        .title {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #757787;
            margin-bottom: 3px;
        }

        .pw {
            font-size: 23px;
            font-weight: 800;
            color: #353d74;
            letter-spacing: -0.06em;

            sup {
                font-size: 10px;
                font-weight: 700;
                top: -15px;
                letter-spacing: 0px;
            }
        }

        .btn-select {
            background: #58a946;
            border-radius: 8px;
            color: white;
            font-size: 16px;
            font-weight: normal;
            line-height: 23px;
            // width: 100%;
            border: none;
            padding: 13px 30px;
            margin-top: 20px;
            transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
                transform 0.1s ease 0s !important;

            &.disabled {
                background: grey;
            }
        }

        .total {
            font-size: 12px;
            letter-spacing: -0.03em;
        }
    }
}

.search-result-box {
    // max-width: 732px;
    margin-bottom: 20px; // padding: 16px 22px;
    transition: all 0.2s;
    overflow: hidden;

    // -webkit-box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    // box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);

    -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    border-radius: 8px !important;

    background-color: #ffffff;
    // border: 1px solid transparent;
    transition: all 0.2s;

    &:hover {
        -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
    }

    // &.selected + .search-result-box {
    //     opacity: 0.2;
    // }
    &.dummy-box {
        opacity: 1;
        .left-content {
            padding-right: 30px;
            padding-left: 20px;
        }
        .right-content {
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
    }

    &.current-flight {
        border: 2px solid $primary;
        border: 2px solid $orange;
    }

    &.selected {
        border: 2px solid $green;
    }

    &.no-select {
        margin-bottom: 10px !important;
        // box-shadow: none!important;
        // padding: 0!important;
        .search-result > div .left-content {
            border-right: none !important;
            > div {
                padding-right: 23px !important;
            }
        }
    }

    .search-result-box__controls {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .search-result-box__highlights {
        float: right;
        color: #7f8190;
        font-weight: 600;
        font-size: 12px;
        text-align: right;

        .includes-checked-baggage {
            cursor: pointer;
        }
    }

    &__baggage {
        color: #19b4d1;
        font-weight: 600;
    }
}

.city-footer-image {
    height: 130px;
    // background: url('/assets/images/cityfooter.png');
    opacity: 0.7;
    background-size: 900px;
    background-position: bottom;
    background-repeat: repeat-x;
}
.filter-button {
    border: 1px solid #d1d1d1 !important;
    background-color: white !important;
    font-weight: 600;
    margin-bottom: 15px;
    border-radius: 12px !important;
    background-color: white !important;
    color: #222222 !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-image: initial !important;
    transition: all 0.2s ease 0s !important;
    border-color: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 2px !important;

    &:hover {
        border-color: #19b4d1 !important;
        color: #19b4d1 !important;
    }
}

.filters-wrapper {
    .loading-shimmer {
        height: 40px !important;
        border-radius: 4px;
        opacity: 0.4;
        padding: 0px;

        &:first-child {
            margin-top: 20px;
        }

        &:nth-child(4) {
            margin-top: 60px;
        }
    }
}

.filters {
    .filters-carriers-controls {
        display: flex;
        margin-bottom: 10px;

        button {
            background: none;
            color: #19b4d1;
            text-decoration: none;
            padding: 0;
            font-weight: 600;
            outline: none;
            font-size: 12px;

            &:disabled {
                color: #b2b2bf;
                cursor: default;
            }
        }

        &__separator {
            width: 0.0625rem;
            margin-right: 0.75rem;
            margin-left: 0.75rem;
            box-shadow: inset 0 0 0 1px #dddde5;
        }
    }

    .filters-accordian {
        margin-bottom: 20px;
    }

    .filters-accordian__heading {
        border-bottom: 1px solid #dddde5;
        margin-bottom: 10px;
        padding-bottom: 5px;
        color: #191b37;
        font-size: 16px;
        font-weight: 600;
    }

    input[type="checkbox"] {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        & + span {
            position: relative;
            cursor: pointer;
            padding: 0;
            color: #3d3e52;
            font-weight: 400;
            font-size: 12px;
            text-transform: capitalize;
        }

        // Box.
        & + span:before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: white;
            border: 1px solid #d1d1d1;
            border-radius: 4px;
        }

        &:hover + span {
            color: #19b4d1 !important;
        }

        // Box focus
        // &:focus + span:before {
        //   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        // }

        // Box checked
        // &:checked + span {
        //     color: #19B4D1!important;
        // }

        // Box checked
        &:checked + span:before {
            background: linear-gradient(45.07deg, #19b4d1 1.48%, #7fd3e3 99.52%);
            border: none;
        }

        // Disabled state span.
        &:disabled + span {
            color: #b8b8b8;
            cursor: auto;
        }

        // Disabled box.
        &:disabled + span:before {
            box-shadow: none;
            background: #ddd;
        }

        // Checkmark. Could be replaced with an image
        &:checked + span:after {
            content: "";
            position: absolute;
            left: 5px;
            top: 9px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
                4px -8px 0 white;
            transform: rotate(45deg);
        }
    }

    .filter {
        input {
            margin-right: 10px;
        }
        label {
            margin-bottom: 8px;
        }
    }

    &__mobile-header {
        padding: 15px 20px;
        box-shadow: 0px -26px 35px 12px;
        background: white;

        .row {
            align-items: center;
        }

        h4 {
            margin-bottom: 0;
            font-size: 22px;
            font-weight: 600;
        }

        span {
            color: #19b4d1;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.search-result {
    border-radius: 4px;
    // -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    // box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    // -webkit-box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    // box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);

    // -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    // box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    // border-radius: 8px !important;

    // background-color: #ffffff;
    // border: 1px solid transparent;
    // transition:all .2s;

    // &:hover {
    //     -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
    //     box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px !important;
    // }

    .btn-select {
        .btn-select-unselect {
            display: none;
        }

        &.selected.Button__loading {
            opacity: 1;
        }

        &.selected {
            opacity: 0.5;
        }
        // &.selected:hover {
        //     background-color: $red!important;
        // }

        // &.selected:hover .btn-select-default {
        //     display: none;
        // }
        // &.selected:hover .btn-select-unselect {
        //     display: inline;
        // }
    }

    .search-result-current-flight {
        color: white;

        background-color: $primary;
        background-color: $orange;
        padding: 8px 20px;
    }
    .search-result-selected {
        color: white;
        background-color: $green;
        padding: 8px 20px;
    }

    &.detail-open {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;

        .flight-details-toggle .content-icon svg {
            transform: rotate(180deg);
        }
    }

    .search-result__segment-airport {
        color: #757787 !important;
        font-weight: normal !important;
        font-size: 16px !important;
    }
    .search-result__segment-time {
        font-weight: 600 !important;
        color: #47495f !important;

        .am-pm {
            font-size: 20px;
            font-weight: normal;
            margin-left: 1px;
            display: inline;
        }
    }

    > div {
        // -webkit-box-align: center;
        // -ms-flex-align: center;
        // align-items: center;

        .left-content {
            border-right: 1px solid #d1d1d1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 20px;
            border-right-style: dashed;

            // &:before {
            //     position: absolute;
            //     top:0;
            //     right:0;
            //     content: "";
            //     display: block;
            //     width: 1.5rem;
            //     height: 1.5rem;
            //     transform: translateZ(0);
            //     border: .375rem solid #fff;
            //     background:#effaff;
            //     border-radius: .75rem;
            //     box-shadow: inset 0 1px 3px 0 rgba(37,32,31,.3);
            // }

            // &:after {

            // }

            > div {
                padding-left: 23px;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;

                > .col-12 {
                    margin-bottom: 15px;
                }

                .address {
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                    flex-direction: row;
                }

                .address-time {
                    flex-basis: 150.19px;

                    p {
                        font-size: 23px;
                        color: #47495f;
                        font-weight: normal;
                        margin: 0;
                        position: relative;

                        span {
                            position: absolute;
                            top: -3px;
                            font-size: 0.75rem;
                        }

                        dd {
                            font-size: 18px;
                        }
                    }

                    > span {
                        font-size: 18px;
                        font-weight: 600;
                        color: #757787;
                        position: relative;

                        span {
                            position: absolute;
                            top: -3px;
                            font-size: 0.75rem;
                        }
                    }
                }

                .more-info {
                    .virgin {
                        width: 26.3px;
                        display: none;
                    }

                    p {
                        font-size: 14px;
                        font-weight: normal;
                        color: #757787;
                        margin: 0;
                    }

                    .direct {
                        color: #58a946;
                    }

                    .divider {
                        height: 2px;
                        border-radius: 39px;
                        background-color: #19b4d1;
                        margin: 13px 0;
                        position: relative;
                        display: flex;
                        justify-content: space-evenly;

                        .dot {
                            width: 8px;
                            height: 8px;
                            border-radius: 100%;
                            object-fit: contain;
                            border: solid 2px #343e74;
                            background-color: #343e74;
                            margin-top: -3px;
                        }

                        svg {
                            font-size: 12px;
                            color: #19b4d1;
                            position: absolute;
                            right: 0;
                            top: -5px;
                            background: white;
                            border-left: 0px solid white;
                            width: 20px;
                            margin-right: -14px;
                        }
                    }

                    .divider-flight {
                        display: flex;
                        justify-content: space-evenly;

                        .flight {
                            font-size: 14px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            color: #343e74;
                        }
                    }
                }

                .airline {
                    padding-left: 0;

                    p {
                        font-size: 12px;
                        font-weight: normal;
                        color: #757787;
                        margin: 0;
                    }

                    img {
                        width: 65.3px;
                        display: block;
                    }
                }
            }
        }

        .right-content {
            text-align: right;
            padding-left: 0;
            width: 15rem;

            > div {
                padding: 10px 20px;
                text-align: center;

                > img {
                    max-width: 128px;
                    margin-bottom: 10px;
                    max-height: 35px;
                    margin-top: 8px;
                }

                .btn-select {
                    font-size: 17px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    text-align: center;
                    color: #ffffff;
                    border-radius: 8px;
                    background-color: #58a946;
                    border: none;
                    margin-bottom: 10px;

                    &:active,
                    &:focus {
                        color: #fff;
                        border-color: none;
                        opacity: 0.9 !important;
                        background-color: #58a946 !important;
                        color: #ffffff;
                    }

                    img {
                        margin-left: 5px;
                    }
                }

                .title {
                    font-size: 12px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    color: #757787;
                    margin-bottom: 3px;
                }

                p {
                    font-size: 22px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    color: #757787;
                    margin: 0;
                }

                .total {
                    font-size: 12px;

                    span {
                        font-size: 10px;
                        font-weight: 100;
                    }
                }

                .pw {
                    font-size: 23px;
                    font-weight: 800;
                    color: #353d74;
                    word-wrap: break-word;
                    letter-spacing: -0.06em;

                    sup {
                        font-size: 10px;
                        font-weight: 100;
                        top: -15px;
                        font-weight: 700;
                        top: -15px;
                        letter-spacing: 0px;
                    }
                }

                .multi-flight {
                    margin-bottom: 5px;
                    .col {
                        &:first-child {
                            padding-right: 0;
                        }

                        &:last-child {
                            padding-left: 0;
                        }

                        .popup-flight {
                            position: relative;
                            z-index: 1;
                            cursor: pointer;

                            // &:hover {
                            //     .content-popup {
                            //         display: block;
                            //     }
                            // }

                            .content-popup {
                                display: none;
                                width: 159px;
                                min-height: 50px;
                                background: #ffffff;
                                position: absolute;
                                right: -10px;
                                top: 33px;
                                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                                border: solid 1px #d1d1d1;
                                padding: 15px;
                                text-align: left;

                                &::before {
                                    bottom: 100%;
                                    right: 5px;
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                    border-color: rgba(194, 225, 245, 0);
                                    border-bottom-color: #d1d1d1;
                                    border-width: 13px;
                                    margin-left: -13px;
                                }

                                &::after {
                                    bottom: 100%;
                                    right: 8px;
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                    border-color: rgba(153, 75, 213, 0);
                                    border-bottom-color: #fff;
                                    border-width: 10px;
                                    margin-left: -10px;
                                }

                                .divider {
                                    margin-bottom: 15px;
                                    width: 100%;
                                    height: 1px;
                                    opacity: 0.5;
                                    background-color: #9cbacf;
                                }

                                .content-sub {
                                    > p {
                                        font-size: 12px;
                                        font-weight: bold;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: normal;
                                        letter-spacing: -0.24px;
                                        color: #343e74;
                                        text-align: left;
                                        margin-bottom: 12px;
                                    }

                                    .details {
                                        border-left: 1px solid #343d73;
                                        padding-left: 10px;

                                        .sub {
                                            margin-bottom: 15px;

                                            p {
                                                font-size: 10px;
                                                font-weight: 600;
                                                font-stretch: normal;
                                                font-style: normal;
                                                line-height: normal;
                                                letter-spacing: -0.2px;
                                                color: #757787;
                                                margin-bottom: 10px;
                                            }

                                            .row {
                                                align-items: center;

                                                .col {
                                                    img {
                                                        max-width: 85px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .content-popup-mobile {
                                width: 213px;
                                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
                                border: solid 1px #d1d1d1;
                                background-color: #ffffff;
                                position: relative;
                                padding: 15px;
                                text-align: left;
                                position: absolute;
                                bottom: 33px;
                                right: calc(50% - 107px);
                                display: none;

                                &.show {
                                    display: block;
                                    right: 15px;
                                }

                                &::before {
                                    top: 100%;
                                    left: 50%;
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                    border-color: rgba(194, 225, 245, 0);
                                    border-top-color: #d1d1d1;
                                    border-width: 13px;
                                    margin-left: -13px;
                                }

                                &::after {
                                    top: 100%;
                                    left: 50%;
                                    border: solid transparent;
                                    content: " ";
                                    height: 0;
                                    width: 0;
                                    position: absolute;
                                    pointer-events: none;
                                    border-color: rgba(153, 75, 213, 0);
                                    border-top-color: #fff;
                                    border-width: 10px;
                                    margin-left: -10px;
                                }

                                .divider {
                                    margin-bottom: 15px;
                                    width: 70%;
                                    height: 1px;
                                    opacity: 0.5;
                                    background-color: #9cbacf;
                                }

                                > img {
                                    position: absolute;
                                    top: 10px;
                                    right: 10px;
                                }

                                .content-sub {
                                    > p {
                                        text-align: left;
                                        font-size: 12px;
                                        font-weight: bold;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: normal;
                                        letter-spacing: -0.24px;
                                        color: #343e74;
                                        margin-bottom: 15px;
                                    }

                                    .details {
                                        border-left: 1px solid #343d73;
                                        padding-left: 10px;

                                        .sub {
                                            margin-bottom: 19px;

                                            .row {
                                                align-items: center;

                                                p {
                                                    font-size: 10px;
                                                    font-weight: 600;
                                                    font-stretch: normal;
                                                    font-style: normal;
                                                    line-height: normal;
                                                    letter-spacing: -0.2px;
                                                    color: #757787;
                                                }

                                                img {
                                                    max-width: 86px;
                                                }

                                                > div {
                                                    &:first-child {
                                                        padding-right: 5px;
                                                    }

                                                    &:nth-child(2) {
                                                        padding: 0;
                                                    }
                                                }

                                                .col {
                                                    padding-left: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    > .col-auto {
                        padding: 0 10px;

                        span {
                            font-size: 14px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            color: #47495f;
                        }
                    }
                }
            }
        }

        .border-none {
            border: none !important;
        }
    }

    .flight-details-toggle {
        text-align: left;
        display: inline-block;
        cursor: pointer;

        .content-icon {
            color: #19b4d1;
            font-weight: 600;
            font-size: 12px;

            svg {
                margin: 0 5px;
            }
        }
    }

    .detail {
        text-align: center;
        //margin-top: 15px;

        .wrap-icon {
            //width: calc(100% - 200px);
            display: flex;
            justify-content: center;
            width: 100%;

            .content-icon {
                width: 25px;
                height: 25px;
                display: inline-block;
                border: 2px solid #eee;
                border-radius: 50%;

                svg {
                    transform: rotate(0deg);
                    transition: transform 1s linear;
                }
            }
        }

        > .content-detail {
            box-shadow: inset 0px 93px 25px -112px;
            padding: 0 25px;
        }
    }
}

@media (max-width: 991px) {
    .search-result {
        > div {
            .left-content {
                > div {
                    .address {
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;

                        .more-info {
                            img {
                                width: 100px;
                            }

                            .virgin {
                                width: 100%;

                                img {
                                    width: 65.3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 927px) {
    .search-result {
        > div {
            .left-content {
                > div {
                    .more-info {
                        .virgin {
                            display: inline-block;
                        }

                        .arrow {
                            width: 100px;
                        }
                    }

                    .airline {
                        display: none;
                    }
                }
            }
        }

        .detail .wrap-icon {
            width: calc(100% - 158px);
        }
    }
}

@media (max-width: 936px) {
    .search-result {
        > div {
            .left-content {
                > div {
                    .time {
                        margin-bottom: 40px;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;

                        span {
                            font-size: 28px;
                            font-weight: normal;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;
                            color: #47495f;
                        }

                        div {
                            display: inline-block;
                        }

                        .from-date {
                            width: calc(50% - 35px);
                        }

                        .arrow {
                            img {
                                width: 70px;
                            }
                        }

                        .to-date {
                            width: calc(50% - 35px);
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .filters-wrapper {
        width: 250px !important;
    }

    .search-result > div .right-content > div .multi-flight .col .popup-flight .content-popup-mobile {
        display: none !important;
    }
}

@include media-breakpoint-down(md) {
    .search-result__segment {
        padding: 0 5px;
    }

    .filters-wrapper {
        .loading-shimmer {
            display: none !important;
        }
    }

    .filters {
        overflow: hidden;
        width: 80%;
        margin-right: -80%;
        position: fixed;
        background: #effaff;
        z-index: 9999;
        top: 0;
        right: 0;
        height: 100vh;
        transition: all 0.4s;

        &.opened {
            margin-right: 0;

            & + .filter-button:before {
                content: "";
                background: rgba(0, 0, 0, 0.6);
                opacity: 1;
                pointer-events: auto;
                position: fixed;
                cursor: default;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 9998;
            }
        }
    }

    .filter-button {
        width: 100%;
    }
    .filters-wrapper {
        position: static !important;
    }

    .filters-accordian {
        padding: 20px 20px 0px;
    }

    .content-search {
        .content {
            padding: 0 1em;
        }
    }
}

@include media-breakpoint-down(xs) {
    .search-result {
        .left-content {
            > div {
                .address-time {
                    flex-basis: 105px !important;
                    padding: 0px 10px;
                    p {
                        font-size: 22px !important;
                        dd {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .search-result-box .search-result-box__controls {
        padding: 0 20px !important;
        margin-bottom: 0px !important;
    }
}

@include media-breakpoint-up(md) {
    .search-result {
        > div {
            .right-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .search-result {
        > div {
            .right-content {
                > div {
                    width: 100% !important;
                }
            }
        }
    }

    .hotel-result-box {
        flex-direction: column;
    }

    .hotel-result-box .hotel-result-box__slider .hotel-result-box__slider-image {
        min-height: 300px;
    }

    .hotel-result-box__slider-content {
        padding-right: 40px !important;

        h2 {
            font-size: 24px !important;
        }
        p {
            font-size: 14px;
        }

        .tag {
            font-size: 11px !important;
        }
    }

    .hotel-result-box__content {
        padding: 30px;
        width: 100% !important;
        justify-content: space-evenly !important;

        .btn-select {
            margin-top: 0px !important;
        }

        .hotel-result-box__content-inner {
            margin-bottom: 20px;
            flex-direction: row;
            display: flex;
            justify-content: space-evenly !important;

            > div {
                justify-content: center;
                display: flex;
                flex-direction: column;
            }

            .hotel-result-box__logo {
                flex: 1 1 150px;
                // padding-right: 20px;
                max-width: 190px;
                display: none;
            }

            // .hotel-result-box__details {
            //     margin-left: 20px;
            // }

            .hotel-result-box__logo {
                img {
                    max-width: 100%;
                }
            }
        }
    }

    .no-change-fees {
        float: none !important;
        text-align: center;
        display: block;
        color: rgb(46, 111, 19);
        font-weight: 600;
        font-size: 11px !important;
        margin-top: 5px;
        svg {
            margin-right: 0px !important;
        }
    }

    .search-result {
        // padding-left:10px;
        // padding-right:10px;
        .detail {
            .wrap-icon {
                width: 100%;
            }
        }

        > div {
            .left-content {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                border: none;
                // margin-bottom: 12px;

                > div {
                    padding: 0px 15px 0 15px;

                    .time {
                        div {
                            display: inline-block;
                        }

                        .from-date {
                            width: calc(50% - 35px);
                        }

                        .arrow {
                            img {
                                width: 70px;
                            }
                        }

                        .to-date {
                            width: calc(50% - 35px);
                        }
                    }
                }
            }

            .right-content {
                text-align: right;
                padding-left: 15px;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                border-top: 1px solid #f2f2f2;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                border: none;

                .btn-select {
                    margin-top: 15px;
                    width: 100%;
                }

                > div {
                    display: inline-block;
                    height: 100%;
                    padding: 15px;
                    text-align: center;
                    border: none;
                    width: 50%;
                    display: inline-block;
                    border-right: 1px solid #d1d1d1;
                    border-top: 1px solid #d1d1d1 !important;

                    &:last-child {
                        border: none;
                    }

                    p {
                        text-align: center;
                        font-size: 18px;
                    }

                    .pw {
                        font-size: 19px;

                        sup {
                            display: none;
                        }
                    }

                    .multi-flight {
                        .col {
                            .popup-flight {
                                .content-popup {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// @media(max-width: 440px) {
//     .search-result .detail>.content-detail {
//         padding: 0 10px;
//     }

//     .content-search {
//         .container {
//             // padding: 0;

//             .search-result {
//                 // overflow: hidden;

//                 >div {
//                     .left-content {
//                         >div {
//                             .address-time {
//                                 flex-basis: 117.13px;

//                                 p {
//                                     font-size: 20px;
//                                 }
//                             }

//                             .more-info {
//                                 padding: 0;

//                                 span {
//                                     font-size: 16px;
//                                 }

//                                 p {
//                                     font-size: 13px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// @media(max-width: 398px) {
//     .search-result>div .right-content>div {
//         padding-left: 7px;
//         padding-right: 7px;
//         .multi-flight {
//             .col-auto {
//                 padding: 0 6px;
//                 span {
//                     font-size: 12px;
//                 }
//             }
//         }
//     }
// }

// @media(max-width: 380px) {
//     .search-result {
//         .left-content {
//             .row {
//                 >div {
//                     padding-left: 8px;
//                 }
//             }
//         }

//         .detail>.content-detail {
//             padding: 0px;

//             .schedule-content {
//                 padding: 22px 5px;

//                 .row {
//                     .col-auto {
//                         padding-right: 0;
//                     }

//                     .detail .address .name {
//                         font-size: 17px;
//                     }
//                 }
//             }
//         }
//     }
// }

// @media(max-width: 350px) {
//     .content-search .container .search-result>div .left-content>div {
//         .address-time {
//             flex-basis: 105px;

//             >span {
//                 font-size: 16px;

//                 >span {
//                     font-size: 14px;
//                 }
//             }

//             p {
//                 font-size: 18px;
//             }
//         }
//     }
// }
