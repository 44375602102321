.ReferHeader {
    padding: 60px 0;
    // background-image: url('/assets/images/hero-images/mykonos.jpg');
    background-size: cover;
    background-position: center center;

    h1,
    p {
        color: white;
        text-align: center;
    }
}

.ReferContent {
    padding-top: 40px;
}

.ReferBox {
    background: white;
    padding: 30px;
    text-align: center;

    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .ReferBox__code {
        font-size: 32px;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: 0.05em;
        color: rgb(8, 210, 178);
        overflow-wrap: break-word;
        text-align: center;
        margin: 24px 0px;
        border-width: 2px;
        border-style: dashed;
        border-color: rgb(8, 210, 178);
        border-image: initial;
        border-radius: 8px;
        padding: 24px 16px;
    }

    .ReferBox__copyButton {
        color: inherit;
        cursor: pointer;
        text-transform: none;
        background: none;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        font-size: 16px;
        margin: 0px;
        outline: none;
        overflow: visible;
        padding: 0px;

        &:hover {
            color: $primary;
        }
    }
}

.Referrals {
    padding: 30px;

    .Referrals__listItem {
        display: flex;
        flex-direction: row;
        border-top: 1px solid whitesmoke;
        padding: 15px 0;

        > div {
            margin-right: 10px;

            &:last-child {
                margin-right: 0px;
            }
        }

        .Referrals__listItem__reference {
            flex: 1 0 0%;
        }

        .Referrals__listItem__amount {
            font-weight: 600;

            &--success {
                color: $success;
            }
        }
    }

    .Referrals__balance {
        font-weight: 600;
        color: black;
    }
}

@include media-breakpoint-down(sm) {
    .Referrals__listItem {
        flex-direction: column !important;
    }
}
