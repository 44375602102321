.content-review {
    background: white;

    .DayPicker {
        margin-top: 10px !important;
    }

    .DateRangePicker__dateInput {
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #c6c6c6;
        padding: 13px 20px;
        font-size: 15px;
        display: flex;
        flex-direction: none;

        &:hover {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }

        .fa-angle-down {
            font-size: 20px;
            color: $primary;
        }

        .DateRangePicker__label {
            margin-right: 10px;
            font-weight: 600;
            color: #47495f;
        }

        .DateRangePicker__dateInputValue {
            flex: 1 0 0%;
        }
    }

    .content {
        min-height: auto;
        background-color: #f4fbff;
        padding: 0px;

        .container {
            padding-top: 38px;
            .btn-confirm-payment {
                background-image: linear-gradient(86deg, #19b4d1, #7fd3e3) !important;
            }

            .payment {
                margin-top: 15px;

                > h3 {
                    font-size: 32px;
                    font-weight: 600;
                    color: #191b37;
                    margin-bottom: 25px;
                }

                > p {
                    font-size: 20px;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 9px;
                }

                > span {
                    font-size: 18px;
                    color: #757787;
                    margin-bottom: 34px;
                    display: block;
                }

                .content-date {
                    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    padding: 30px;

                    .select-date {
                        border-radius: 4px;
                        background-color: #ffffff;
                        border: 1px solid #c6c6c6;
                        padding: 12px 20px;

                        .row {
                            span {
                                font-size: 15px;
                                font-weight: 300;
                                color: #47495f;
                            }

                            .text-right {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }

            .search-result-box__flight-details {
                max-width: 640px;
                border-top: none;
                background: white;
                border-radius: 8px;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            }

            .payment-plan {
                margin-top: 10px;

                > h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 9px;
                }

                > p {
                    font-size: 18px;
                    color: #757787;
                    margin-bottom: 33px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                }

                .method-payment {
                    cursor: pointer;
                    border-radius: 4px;
                    border: 1px solid #c6c6c6;
                    padding: 13px 20px;
                    margin-bottom: 15px;

                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    .ant-radio-wrapper-checked {
                        .ant-radio-checked {
                            .ant-radio-inner {
                                background: #58a946;
                                border-color: #58a946;

                                &::after {
                                    background-color: #fff;
                                    width: 6px;
                                    height: 6px;
                                    left: 4px;
                                    top: 4px;
                                }
                            }
                        }
                    }

                    span {
                        font-size: 15px;
                        font-weight: 300;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;

                        color: #47495f;
                    }

                    .bold {
                        font-weight: 600;
                    }
                }

                .selected-plan {
                    border: solid 2px #58a946;
                }

                .content {
                    padding: 30px;
                    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    background: #fff;

                    .info {
                        margin-top: 19px;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;

                        h3 {
                            font-size: 24px;
                            font-weight: normal;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;

                            color: #191b37;
                            margin-bottom: 6px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: normal;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 1.63;

                            color: #47495f;

                            a {
                                color: #47495f;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .card-detail {
                margin-top: 35px;

                .select-method-payment {
                    margin: 20px 0;
                }

                .exists-card {
                    padding-left: 5px;

                    > div {
                        margin-bottom: 10px;
                    }
                }

                > h3 {
                    font-size: 20px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;

                    color: #000000;
                    margin-bottom: 16px;
                }

                .content {
                    background: #ffffff;
                    padding: 30px;
                    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 8px;

                    .title {
                        span {
                            font-size: 12px;
                            font-weight: normal;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;

                            color: #5f5f5f;
                        }
                    }

                    .card-image {
                        .payment-card {
                            width: 249px;
                            margin-left: -11px;
                        }

                        .mcafee-secure {
                            width: 80px;
                        }
                    }

                    .info-card {
                        margin-top: 30px;

                        label {
                            font-size: 15px;
                            font-weight: 600;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;

                            color: #47495f;
                        }

                        .card-content {
                            width: 100%;

                            #field-wrapper {
                                border: 1px solid #ced4da;
                                border-radius: 0.25rem;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -ms-flex-pack: center;
                                min-height: 34px;
                                position: relative;

                                img {
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    -ms-flex-negative: 0;
                                    flex-shrink: 0;
                                    -ms-flex-preferred-size: 22px;
                                    flex-basis: 22px;
                                }

                                label {
                                    margin-bottom: 0;

                                    &:nth-of-type(1) {
                                        -webkit-box-flex: 0;
                                        -ms-flex-positive: 0;
                                        flex-grow: 0;
                                        -ms-flex-negative: 0;
                                        flex-shrink: 0;
                                        -ms-flex-preferred-size: calc(100% - 160px - 22px);
                                        flex-basis: calc(100% - 160px - 22px);
                                    }

                                    &:nth-of-type(2) {
                                        -webkit-box-flex: 0;
                                        -ms-flex-positive: 0;
                                        flex-grow: 0;
                                        -ms-flex-negative: 0;
                                        flex-shrink: 0;
                                        -ms-flex-preferred-size: 80px;
                                        flex-basis: 80px;
                                    }

                                    &:nth-of-type(3) {
                                        -webkit-box-flex: 0;
                                        -ms-flex-positive: 0;
                                        flex-grow: 0;
                                        -ms-flex-negative: 0;
                                        flex-shrink: 0;
                                        -ms-flex-preferred-size: 80px;
                                        flex-basis: 80px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .email {
                margin-top: 35px;

                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;

                    color: #000000;
                    margin-bottom: 25px;
                }

                .content {
                    background: #fff;
                    padding: 30px;
                    border-radius: 4px;
                    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

                    span {
                        font-size: 12px;
                        font-weight: 600;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;

                        color: #757787;
                        margin-bottom: 14px;
                    }
                }
            }

            .total {
                margin-top: 35px;

                > p {
                    font-size: 20px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;

                    color: #000000;
                    margin-bottom: 25px;

                    span {
                        font-size: 15px;
                        font-weight: 600;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;

                        color: #757787;
                    }

                    .dot {
                        width: 3px;
                        height: 3px;
                        background: #757787;
                        display: inline-block;
                        border-radius: 50%;
                        margin-bottom: 2px;
                    }
                }

                .content {
                    background: #fff;
                    padding: 20px;
                    border-radius: 8px;
                    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

                    .payment-wrap {
                        &.no-payment-required {
                            .row:not(.discount):not(.total-row) {
                                opacity: 0.3;
                                pointer-events: none;
                            }
                        }

                        .row {
                            .payment-list-head {
                                font-family: Open Sans;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 27px;
                                color: #000000;
                                margin-bottom: 15px;
                            }

                            .payment-list-sub-title {
                                font-size: 16px;
                                color: #757787;
                                font-weight: normal;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: normal;
                            }
                        }
                        .content-date {
                            padding: 0px 10px;
                            height: 45px;
                            border: 1px solid #c6c6c6;

                            &:hover {
                                cursor: pointer;
                            }

                            .select-date {
                                .title {
                                    margin-right: 10px;
                                }
                                .frequency-list {
                                    width: 100%;
                                    margin: 12px 5px;
                                    z-index: 999;
                                    ul {
                                        padding: 10px 0;
                                        margin: 0;
                                        border: 1px solid #c6c6c6;
                                        border-radius: 4px;
                                        background-color: #fff;

                                        li {
                                            list-style: none;
                                            text-align: left;
                                            padding: 10px 15px;

                                            &:hover {
                                                cursor: pointer;
                                                background-color: #f1f1f1;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .payment-select {
                            > select {
                                height: 45px;
                            }
                        }
                    }
                    .row {
                        padding: 13px 0;

                        &:last-child {
                            border: none;
                        }

                        .title {
                            font-size: 15px;
                            font-weight: 600;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;

                            color: #47495f;
                        }

                        .starting {
                            font-size: 14px;
                            font-weight: bold;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;

                            color: #58a946;
                        }

                        .price {
                            span {
                                font-size: 18px;
                                font-weight: normal;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: normal;

                                color: #8b8b8b;

                                &:last-child {
                                    font-size: 18px;
                                    font-weight: 600;
                                    text-align: right;
                                    color: #191b37;
                                }
                            }
                        }
                        .repayments {
                            span {
                                &:last-child {
                                    font-size: 28px;
                                }
                            }
                        }
                    }
                }
            }

            .end {
                margin-top: 23px;

                .agree {
                    font-size: 12px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;

                    text-align: center;
                    color: #5f5f5f;
                    margin-bottom: 25px;

                    a {
                        color: #5f5f5f;
                        text-decoration: underline;
                    }
                }

                button {
                    white-space: initial;
                    width: 100%;
                    border-radius: 4px;
                    background: #58a946;
                    border: none;
                    font-size: 17px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;

                    text-align: center;
                    color: #ffffff;
                    margin-bottom: 40px;
                }

                .speak {
                    display: block;
                    margin-bottom: 29px;
                }

                .things {
                    font-size: 18px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;

                    color: #191b37;
                    margin-bottom: 14px;
                }

                span {
                    font-size: 16px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.63;

                    color: #757787;
                }
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .content-review .content .container .card-detail .content .info-card .card-content #field-wrapper {
        img {
            position: absolute;
        }
        label {
            margin-top: -20px;
            &:nth-of-type(1) {
                margin-left: 22px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .content-review {
        .content {
            .container {
                .card-detail {
                    .content {
                        .card-image {
                            .col-auto {
                                padding-right: 0;
                            }

                            .payment-card {
                                width: 200px;
                            }

                            .mcafee-secure {
                                width: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
}
