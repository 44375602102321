.SearchForm {
    // tabs
    .SearchForm__tabs {
        border-bottom: none;
        cursor: pointer;
        .nav-item {
            margin-right: 5px;
            margin-bottom: 0px;

            .nav-link {
                background: #a7c2d1;
                border: none;
                padding: 10px 28px;
                color: #191a1b;
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;

                &:hover {
                    background-color: #91afbf;
                }
                &.active {
                    background-color: #d2e0e8;
                }
            }
        }
    }

    .SearchForm__content {
        border-radius: 12px;
        border-top-left-radius: 0px;
        -webkit-box-shadow: 0 12px 22px 0 rgba(25, 27, 55, 0.5);
        box-shadow: 0 12px 22px 0 rgba(25, 27, 55, 0.5);
        background-color: #d2e0e8;
        padding: 30px;
        border: none;

        .close.rbt-close {
            position: absolute;
            right: -10px;
            top: -15px;
            bottom: 0;
            padding: 0px 10px 0px 40px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 30%);
            opacity: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            margin: 2px;
            height: 24px;
            &:hover {
                opacity: 1 !important;
            }
        }

        input[type="text"] {
            height: 45px;
            color: #191a1b;
        }
    }

    .SearchForm__multiCity {
        .fa-times {
            transition: all 0.2s;
            cursor: pointer;

            &.disabled {
                opacity: 0.3;
                cursor: not-allowed;
            }
        }
        .autoComplete {
            input {
                border-left: 1px solid #ced4da;
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }
        }

        &__leg-separator {
            max-width: 15px;
        }

        &__remove-leg {
            max-width: 40px;
        }
    }

    .SearchForm__multiCity__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .SearchForm__dropdown {
            flex: 0 0 250px;
        }

        .SearchForm__submit {
            flex: 0 0 200px !important;
        }
    }

    .SearchForm__multiCity__leg {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        position: relative;

        .SearchForm__input {
            margin-right: 10px;
            flex: 2 0 0%;
        }

        .DateRangePicker__dates {
            margin-right: 15px;
            flex: 0.5 0 100px !important;
        }
    }

    .SearchForm__radio-label {
        margin-right: 15px;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 15px;
        cursor: pointer;

        input {
            margin-right: 3px;
            margin-top: 3px;
        }
    }

    .SearchForm__submit {
        border-radius: 8px;
        background: #58a946;
        font-size: 17px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        text-align: center;
        color: #ffffff;
        border: none;
        margin-right: 0px;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 15px;
        width: 100%;
        flex: 0.5 0 auto !important;

        &:hover,
        &:active,
        &:focus {
            color: #fff;
            border-color: none;
            opacity: 0.8;
            background-color: #58a946 !important;
            color: #ffffff;
        }
        &:active,
        &:focus {
            color: #fff;
            border-color: none;
            opacity: 0.9 !important;
            background-color: #58a946 !important;
            color: #ffffff;
        }
    }

    .SearchForm__typeahead {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        border-left: none !important;

        .rbt-input-hint-container {
            overflow: hidden;
        }

        .dropdown-menu li a {
            padding: 10px 20px;

            .typeahead-country {
                font-size: 12px;
                color: grey;
            }
        }

        .dropdown-item span {
            font-weight: normal;
            color: #191a1b !important;
        }

        .dropdown-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.active {
                background-color: rgb(234, 234, 234);
            }
            &:active {
                background-color: rgb(218, 218, 218);
            }
        }

        .dropdown-menu li:last-child {
            border-bottom: 0px;
        }

        &:focus + .input-group-text {
            border: 2px solid #58a946;

            input {
                line-height: 17px;
            }
        }

        .rbt-input-hint {
            display: none;
        }
    }

    .SearchForm__label,
    .DateRangePicker__label {
        font-weight: 700;
        font-size: 12px;
        color: black;
        line-height: 16px;
        letter-spacing: normal;
    }

    button:not(.btn-search):not(.close) {
        transition: none;

        &:hover {
            box-shadow: inset 0px 0px 0px 2px $primary;
        }
    }

    .form-control,
    .content-date,
    .input-group-text {
        border-radius: 8px;
    }

    .DateRangePicker__dates {
        flex: 1 0 0% !important;
        display: flex;
        flex-direction: row;
    }

    .SearchForm__stays .DateRangePicker__dates {
        flex: 2 0 0% !important;
    }

    .SearchForm__input {
        border: 1px solid #d1d1d1;
        border-radius: 8px;
        background: white;
        flex: 1 0 0%;
        padding: 8px 14px;
        cursor: pointer;

        &:hover .close.rbt-close {
            opacity: 1 !important;
            float: right;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            top: -9px;
            color: #000;
            text-shadow: 0 1px 0 #fff;
        }

        input {
            padding: 0px;
            border: none !important;
            font-size: 100% !important;
            line-height: 1.5;
            color: #1e1e1e;
            height: auto !important;
        }

        &:hover,
        &--focused {
            box-shadow: inset 0px 0px 0px 2px $primary;
        }
    }

    .DateRangePicker__dateInput {
        border: 1px solid #d1d1d1;
        border-radius: 8px;
        background: white;
        flex: 1 0 0%;
        padding: 8px 14px;
        cursor: pointer;

        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-right: 0px !important;
        }

        &:hover,
        &--focused {
            box-shadow: inset 0px 0px 0px 2px $primary;
        }
    }

    .DateRangePicker__dateInputValue,
    .DateRangePicker__fieldValue,
    .SearchForm__fieldValue {
        font-size: 14px !important;
        line-height: 1.5;
        color: #1e1e1e;
    }

    .SearchForm__flights {
        .btn-search {
            width: 250px;
            float: right;
        }
    }
    .SearchForm__flightsSingleDestination {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;

        > * {
            margin-right: 10px;
            flex: 1 0 0%;
        }

        button:not(.btn-search):not(.close) {
            transition: none;

            &:hover {
                box-shadow: inset 0px 0px 0px 2px $primary;
            }
        }

        .dropdown-passengers {
            margin-right: 0px;
        }
    }

    .SearchForm__stays {
        display: flex;
        flex-direction: row;

        > * {
            margin-right: 10px;
            flex: 1 0 0%;
        }

        .SearchForm__staysDestination {
            flex: 2 0 0%;

            button {
                padding: 8px 14px;
            }
        }

        .SearchForm__submit {
            width: auto !important;
        }

        .SearchForm__staysDestination {
            .dropdown-menu input {
                width: 100%;
                border: none;
                padding: 0 20px 7px;
                font-size: 14px;
                height: auto;
            }
            .dropdown-item {
                border-bottom: 1px solid #dfe4e8;
                padding: 7px 20px;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .SearchForm__multiCityAddFlight {
        color: $primary;
        background-color: transparent;
        font-weight: 600;
        font-size: 16px;
        margin: 5px 0 15px;
        display: block;

        &:hover {
            color: $primary;
        }
    }

    .SearchForm__dropdownToggleButton {
        display: block;
        width: 100%;
        text-align: left;
        background: #fff !important;
        color: #1e1e1e !important;
        border: 1px solid #d1d1d1;
        height: 100%;
        padding: 0px 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 28px;
        border-radius: 8px;
        padding: 8px 14px;

        &:after {
            content: none !important;
        }
    }

    .SearchForm__dropdownMenu,
    .dropdown-menu {
        width: 100%;
        // border-radius: 8px;
        // margin-top: 5px;
        // box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        border: none;
        margin-top: 10px;
        padding: 15px 0;
        // width: 220px;
    }
}

@include media-breakpoint-down(sm) {
    .SearchForm {
        padding: 20px;

        .SearchForm__multiCity {
            .SearchForm__typeahead {
                position: static !important;

                .dropdown-menu {
                    transform: none !important;
                    top: 50px !important;
                    width: 100% !important;
                }
            }

            .DayPicker {
                left: 0;
            }
        }

        .SearchForm__submit {
            width: 100% !important;
            float: none !important;
        }

        .SearchForm__flightsSingleDestination {
            margin-bottom: 0px;

            .rbt-close-content {
                font-size: 20px;
                font-weight: 900;
            }
        }

        .SearchForm__stays,
        .SearchForm__flightsSingleDestination {
            flex-direction: column;

            > * {
                margin-right: 0px;
            }
        }

        .dropdown-passengers {
            margin-bottom: 10px;
        }

        .DateRangePicker__dates {
            margin-bottom: 10px;
        }

        &__content {
            border-top-right-radius: 0px;
            padding: 20px !important;

            .close.rbt-close {
                opacity: 1 !important;
            }
        }

        .SearchForm__multiCity {
            .rbt-aux {
                position: relative;
                left: 50%;
                top: -12px;

                .rbt-close-content {
                    font-size: 20px;
                    font-weight: 900;
                }
            }

            &__location-col {
                max-width: 85px;

                input[type="text"] {
                    padding: 12px 15px;
                }
            }

            &__leg-separator {
                max-width: 5px;
                margin-left: -3px;
            }

            &__remove-leg {
                max-width: 30px;
                margin-left: -3px;
            }
        }

        .SearchForm__multiCity__footer {
            flex-direction: column !important;

            .SearchForm__dropdown {
                flex: auto !important;
            }

            .SearchForm__submit {
                flex: auto !important;
            }
        }

        .SearchForm__staysDestination,
        .SearchForm__input {
            margin-bottom: 10px;
        }

        .form-group {
            margin-bottom: 10px;
        }

        .SearchForm__tabs .nav-item {
            width: 28%;
            margin-right: 0.875%;

            &:last-child {
                margin-right: 0;
            }

            .nav-link {
                width: 100%;
                text-align: center;
            }
        }
    }
}
