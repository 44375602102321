.rating-slider {
    .slick-slide {
        opacity: 0.8;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .content-rating {
        > div {
            background: #fff;
            margin: 10px;
            padding: 30px;
            min-height: 400px;

            .user {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-bottom: 13px;

                .col-auto {
                    padding-right: 0;
                }

                img {
                    width: 39px;
                    border-radius: 50%;
                }

                h4 {
                    font-size: 13.6px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;

                    color: #191b37;
                }
            }

            .content {
                font-size: 15.3px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.44;

                color: #47495f;
                text-align: left;
            }

            .year {
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;

                text-align: right;
                color: #58a946;
            }
            .star-ratings {
                .widget-svg {
                    width: 15.6px;
                    height: 15.6px;
                }
            }
        }
    }

    .slick-slide.slick-current {
        color: #e67e22;
        opacity: 1;
        -webkit-transform: scale(1.13);
        transform: scale(1.13);

        .content-rating {
            > div {
                margin-left: 50px;
                margin-right: 50px;
            }
        }
    }

    .pre-next-btn {
        margin-top: 26px;

        span {
            cursor: pointer;
            width: 41px;
            height: 41px;
            border: 1px solid #d1d1d1;
            border-radius: 50%;
            display: inline-block;
            line-height: 35px;
            text-align: center;

            &:first-child {
                margin-right: 70px;

                img {
                    -webkit-transform: rotate(-180deg);
                    transform: rotate(-180deg);
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .rating-slider {
        .slick-slide.slick-current {
            .content-rating {
                > div {
                    margin-left: 40px;
                    margin-right: 40px;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .rating-slider {
        .content-rating {
            > div {
                background: #fff;
                margin: 5px;
                padding: 20px;

                .user {
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    margin-bottom: 13px;

                    h4 {
                        font-size: 12.1px;
                        font-weight: 600;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;

                        color: #191b37;
                    }
                }

                .content {
                    font-size: 13.8px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.44;

                    color: #47495f;
                    text-align: left;
                }

                .year {
                    font-size: 14.2px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;

                    text-align: right;
                    color: #58a946;
                }
            }
        }

        .slick-slide.slick-current {
            .content-rating {
                > div {
                    margin-left: 30px;
                    margin-right: 30px;
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .rating-slider {
        .content-rating {
            > div {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .slick-slide.slick-current {
            .content-rating {
                > div {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }
}

@media (max-width: 337px) {
    .rating-slider {
        .content-rating {
            padding: 30px 10px;

            > div {
                .start {
                    > div {
                        width: 100%;
                    }
                }
            }
        }

        .slick-slide.slick-current {
            .content-rating {
                > div {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }

        .pre-next-btn {
            span {
                &:first-child {
                    margin-right: 20px;
                }
            }
        }
    }
}
