.children-ages {
    h4 {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 600;
    }

    select {
        display: inline-block;
        width: 100%;
        width: 48%;
        margin-top: 0.75rem;
        height: 2.25rem;
        padding: 0.375rem 1.875rem 0.375rem 0.75rem;
        border: 0.0625rem solid #b2b2bf;
        border-radius: 0.25rem;
        background-size: 1.125rem 1.125rem;
        color: #111236;
        line-height: 1.125rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    select:nth-of-type(odd) {
        margin-right: 2%;
    }

    select:nth-of-type(2n) {
        margin-left: 2%;
    }

    select:after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        position: absolute;
        right: 10px;
        color: #bfbfbf;
        margin-top: 9px;
    }
}

.dropdown-passengers {
    display: block !important;
    width: 100%;

    > .dropdown-toggle {
        display: block;
        width: 100%;
        text-align: left;
        background: #fff !important;
        color: #757787 !important;
        border: 1px solid #d1d1d1;
        height: 45px;
        padding: 0px 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 28px;

        &:hover {
            background: #fff;
            color: #8b8b8b;
            border-color: #fae9e9;
        }
        &::after {
            position: absolute;
            right: 10px;
            color: #bfbfbf;
            margin-top: 9px;
        }
        &:focus {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            // border: 2px solid #58a946 !important;
            // background: #fff;
            // color: #757787;
            outline: none;
        }
    }
    .dropdown-menu {
        width: 100%;
        min-height: 45px;
        padding-top: 14px;
        min-width: 310px !important;

        .item {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 14px;
            .row {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
            .number {
                font-size: 18px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;

                color: #191b37;
                margin-right: 10px;
            }
            .title {
                font-size: 16px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;

                color: #353d74;
                .note {
                    font-weight: 300;
                }
            }
            .btn-count {
                background-color: #ffffff;
                font-size: 28px;
                font-weight: bold;
                color: #19b4d1;
                border: 1px solid #e1e1e1;
                border-radius: 100%;
                width: 40px;
                height: 40px;
                padding: 0px;
                text-align: center;
                &:first-child {
                    margin-right: 3px;
                }

                &:hover {
                    box-shadow: none;
                    border-color: $primary;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}
