.EditSearch {
    flex: 0 1 auto !important;
    transition: all 0s;
    height: 100%;
    align-items: center;
    -webkit-box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 2px !important;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 2px !important;
    border-radius: 8px !important;
    background-color: #ffffff;
    border: 1px solid rgb(247, 247, 247) !important;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 1px 2px !important;
    display: inline-flex !important;
    max-width: 100% !important;
    min-width: 260px !important;
    position: relative !important;
    background-color: rgb(255, 255, 255) !important;
    color: rgb(34, 34, 34) !important;
    border-width: 1px !important;
    border-style: solid !important;
    border-image: initial !important;
    border-radius: 12px !important;
    border-color: rgb(247, 247, 247) !important;
    margin: 0 10px;
    // overflow: hidden!important;
    // transition:width .4s;

    &.expanded {
        flex: 1 0 auto !important;
    }

    .SearchForm {
        width: 100% !important;

        .SearchForm__content {
            padding: 20px !important;
            background: white !important;
            box-shadow: none !important;
        }

        // .btn.btn-search {
        //     background-color: white!important;
        //     flex-grow: 0!important;
        //     padding: 15px!important;
        //     color: #19B4D1!important;

        //     &:hover {
        //         background-color: whitesmoke!important;
        //     }
        // }

        .SearchForm__submit {
            // width: 250px!important;
            // float: none;
            // margin: 0 auto;
            background-color: $primary !important;
            color: white !important;
            margin-right: 0px;

            &:hover {
                background-color: $primary !important;
            }
        }

        .SearchForm__stays {
            .SearchForm__submit {
                flex: 0 0 auto !important;
            }
        }
    }

    .EditSearch__info:last-child {
        padding-right: 0px;
        border-right: none;
    }

    .EditSearch__currentSearchDetails {
        display: flex;
        align-items: center !important;
        vertical-align: middle !important;
        cursor: pointer;

        > .EditSearch__info {
            color: #484848;
            font-weight: 600;
            font-size: 14px;
            padding: 0px 10px;
            border-right: 1px solid rgb(235, 235, 235) !important;

            .EditSearch__infoDates {
                padding-right: 10px;
                border-right: 1px solid rgb(235, 235, 235) !important;
            }
            .EditSearch__infoTravellers {
                padding-left: 10px;
            }

            &:first-child {
                padding-left: 20px;
            }
            &.passengers {
                padding-right: 0px;
                border-right: none !important;
            }
        }
    }

    &__icon {
        font-size: 16px;
        color: #19b4d1;
        padding: 0 15px;
        margin-top: 1px;
    }

    &__details > span {
        margin-right: 5px;
        font-size: 12px;
    }

    &__infoDatesAndTravellers {
        display: flex;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18) !important;

        // .EditSearch__info {

        //     border-right:1px solid rgb(235, 235, 235) !important;

        //     &:first-child {
        //         padding-right:20px;
        //     }

        //     &:not(:first-child){
        //         width:50%;
        //         padding:0 20px;
        //     }

        // }
    }
}

.content-search {
    .EditSearch {
        margin: 0px 0px -5px;
        // border-radius:0!important;
        // height:auto!important;
        // a {
        //     flex-direction:column;
        //     align-items:flex-start!important;
        // }
    }
}

@include media-breakpoint-down(lg) {
    .content-search .EditSearch {
        display: flex !important;
    }
    .EditSearch__long-locations {
        display: none;
    }
    .EditSearch__short-locations {
        display: inline;
    }
}

@include media-breakpoint-down(md) {
    .EditSearch {
        margin: 0;
        margin-top: 5px !important;
        border-radius: 12px !important;
        // border: none!important;
        // border-top: 1px solid rgba(0,11,38,0.12)!important;
        // box-shadow: none!important;
        padding: 10px 5px !important;

        .SearchForm {
            padding: 0px 5px;

            .btn.btn-search {
                background-color: $primary !important;
                color: white !important;
            }
        }

        &.expanded {
            flex-direction: column;

            .close {
                font-size: 14px;
                text-align: center;
                margin-top: 10px;
                font-weight: 400;
            }
        }

        .EditSearch__info {
            &:first-child {
                padding-left: 10px !important;
            }
        }

        .EditSearch__currentSearchDetails {
            flex-direction: column;
            width: 100%;
            align-items: flex-start !important;

            .EditSearch__icon {
                position: absolute;
                padding: 0 12px !important;
                top: 18px;
                right: 5px;
            }
            .EditSearch__info:first-child {
                border-right: none !important;
            }

            .EditSearch__infoDatesAndTravellers {
                padding-left: 10px !important;
                font-size: 12px !important;
                color: $primary !important;
                border-right: none !important;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .content-search {
        padding-top: 15px;
    }
    .navbar .EditSearch {
        width: 100%;
        order: 2;
        display: none !important;
    }
}

@include media-breakpoint-up(lg) {
    .navbar .EditSearch {
        display: flex !important;
    }
    .content-search .EditSearch {
        display: none !important;
    }

    .ChangeFlights .content-search .EditSearch {
        display: block !important;
    }
}
