// NOTE: the order of these styles DO matter

.CalendarDay__default {
    border: none;
    // border-radius: 100%;
    // font-weight: 600;
}
.CalendarDay__default:hover {
    border: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: $primary;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    border-radius: 0px;
    background: #d5d6d6;
    color: #484848;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover {
    border-radius: 0px;
    background: whitesmoke;
    color: #484848;
}

// // Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
    border-radius: 0px;
    background: #e4e7e7;
    color: #484848; //text
    // border: 1px solid $light-red; //default styles include a border
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    // background: #00a699;
    border-radius: 0px;
    // color: #fff;
}

.DateInput {
    width: 50%;
}
.DateInput_input {
    display: block;
    width: 100%;
    text-align: left;
    background: #fff !important;
    color: #1e1e1e !important;
    // border: 1px solid #d1d1d1;
    // height: 45px;
    padding: 2px 20px 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // padding-right: 28px;
    // border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    border-bottom: none !important;
    cursor: pointer;
    height: auto !important;
}

.DateRangePickerInput {
    .DateInput:first-of-type {
        width: 44%;
    }
}

.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: none !important;
    border-left: 0;
}

.DateRangePickerInput__withBorder {
    border: none;
    border-radius: 8px;
    overflow: hidden;
    padding-right: 0px;
}

.DateRangePickerWrapper {
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    background: white;
    // overflow: hidden;

    .DateRangePickerWrapper__labels {
        label {
            display: inline-block;
            width: 50%;
            padding: 10px 20px 0px !important;
            margin-bottom: 0px;

            &:first-child {
                width: 44%;
            }
        }
    }
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 10px;
    width: 10px;
    vertical-align: middle;
}

.CalendarMonth_table {
    margin-top: 8px;
}

// .DayPickerNavigation_button__default {
//     border:none;
// }

.DayPicker_weekHeader_li small {
    font-weight: 600;
}

// .DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:hover {
//     border: none;
//     background: rgb(247, 247, 247) !important;
//     border-radius: 100%;
//     text-align: center;
//     padding: 7px 7px;
// }

.DayPickerNavigation_button {
    z-index: 9999;
}

.DayPicker__withBorder {
    border: none !important;
    box-shadow: none !important;
}

.DayPicker,
.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
    border-radius: 8px !important;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
    background: rgb(255, 255, 255) !important;
    // padding: 15px !important;
    margin-top: 60px !important;
}
.dates-wrapper {
    position: absolute;
}

.DayPickerNavigation_button__disabled {
    display: none;
}

@include media-breakpoint-down(sm) {
    .dates-wrapper.vertical {
        z-index: 9999;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: white;

        .dates-close {
            color: grey;
            font-size: 22px;
            margin-top: 10px;
            margin-left: 12px;
            cursor: pointer;
        }

        .DayPicker,
        .DateRangePicker_picker {
            // z-index: 9999;
            // background-color: #fff;
            // position: fixed;
            border-radius: 8px !important;
            overflow: hidden;
            box-shadow: none !important;
            background: white !important;
            margin-top: 35px !important;
            // height: 100%;
            width: 100%;
            height: calc(100% - 31px);
            left: 0;
            top: 0;
        }
    }

    // .DayPickerNavigation__verticalScrollable_prevNav {
    //     display: none;
    // }

    // .CalendarMonthGrid__vertical_scrollable {
    //     margin-top: 20px;
    // }
}

.customDateRangePicker {
    .customDatePickerWidth,
    .customDatePickerWidth > div.react-datepicker-wrapper,
    .customDatePickerWidth
        > div
        > div.react-datepicker__input-container
        .customDatePickerWidth
        > div
        > div.react-datepicker__input-container
        input {
        width: 100%;
    }
}
