.content-confirm {
    // border-top: 1px solid #d1d1d1;
    background-color: #f4fbff;

    .btn-primary {
        border-radius: 8px;
        background: $primary;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;

        text-align: center;
        color: #ffffff;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        vertical-align: middle;
    }

    .max-w-640 {
        margin: 0 auto;
        > h3 {
            margin-top: 38px;
            font-size: 32px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;

            color: #191b37;
            margin-bottom: 25px;
        }
        .content {
            border-radius: 8px;
            -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
            padding: 30px;
            margin-bottom: 25px;
            .row {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
            p {
                font-weight: normal;
                margin-bottom: 10px;
                // font-size: 24px;
                // font-weight: normal;
                // font-style: normal;
                // font-stretch: normal;
                // line-height: normal;
                //
                // color: #191b37;
                // margin: 0;
                // margin-bottom: 6px;
            }
            h4 {
                margin-bottom: 20px;
            }
            span {
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.63;

                color: #47495f;
                b {
                    font-weight: 600;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .content-confirm {
        padding: 0 !important;
        .max-w-640 {
            > h3 {
                padding: 0 15px;
            }
            .content {
                padding: 15px;
                .checked-img {
                    width: 70px;
                }
            }
        }
    }
}
