.HowItWorks {
    .HowItWorks__hero {
        // background-image: url('/assets/images/hero-images/hutsonabeach.jpg');
        background-size: cover;
        background-position: center center;
        padding: 140px 0 0;
        text-align: center;
        color: white;

        h1 {
            color: white;
            font-size: 56px;
            margin-bottom: 10px;
        }

        p {
            font-size: 22px;
            margin-bottom: 120px;
            display: inline-block;
        }
    }

    .IconBox h3 {
        font-size: 22px;
    }

    .HowItWorks__tabs {
        justify-content: center;
        border-bottom: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        margin-top: 20px;

        .nav-item {
            overflow: hidden;

            &:first-child {
                border-top-left-radius: 8px;
            }

            &:last-child {
                border-top-right-radius: 8px;
            }
        }
        .nav-link {
            padding: 20px 60px;
            background: #dbe2e7;
            color: #47495f;
            font-size: 16px;
            border-radius: 0;
            border-color: #dbe2e7;
            border-bottom-color: rgba(0, 0, 0, 0.15);

            &.active {
                border-color: white;
                border-right-color: rgba(0, 0, 0, 0.15);
                border-left-color: rgba(0, 0, 0, 0.15);
                background: white;
            }
        }
    }

    .HowItWorks__flightsCVP,
    .HowItWorks__staysCVP {
        padding: 100px 0;

        .btn {
            margin-top: 20px;
        }
    }

    .HowItWorks__staysCVP {
        padding: 0 0;
    }
}

.HowItWorks__flightsCVP__disclaimer {
    font-size: 11px;
    margin-top: 20px;
    color: grey;
}

.HowItWorks__flightsCVP__diagram {
    background: white;
    border-radius: 12px;
    padding: 50px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 76px !important;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 76px !important;

    img {
        max-width: 100%;
    }
}

.sticky-outer-wrapper.active {
    .HowItWorks__tabs {
        border-bottom: none;
    }
}

@include media-breakpoint-down(sm) {
    .HowItWorks {
        .HowItWorks__hero {
            padding-top: 60px;

            h1 {
                font-size: 40px;
            }
            p {
                font-size: 16px;
                max-width: 300px;
                margin: 0 auto 60px;
            }
        }

        .HowItWorks__tabs {
            .nav-link {
                padding: 15px 30px;
            }
        }

        .HowItWorks__staysCVP,
        .HowItWorks__flightsCVP {
            padding: 60px 0 !important;
        }

        .HowItWorks__flightsCVP__diagram {
            margin-top: 30px;
        }
    }
}

.how-it-work-container {
    .width-100-per {
        width: 100%;
    }

    > .header {
        // background-image: url('/assets/images/how-it-work/desktop-cover.png');
        background-size: cover;
        padding: 30px;

        .header-content {
            margin-top: 120px;
            margin-bottom: 100px;

            .title {
                font-size: 56px;
                color: white;
                font-weight: 800;
                margin-bottom: 15px;
            }

            .sub-title {
                color: white;
                font-size: 24px;
            }
        }
    }

    > .content {
        color: #191b37;
        padding-top: 144px;
        position: relative;
        min-height: 200px;

        h2 {
            color: #191b37;
            font-size: 32px;
            font-weight: 600;
        }

        p {
            font-size: 22px;
            font-weight: normal;
            line-height: 34px;
        }

        .f-28 {
            font-size: 28px;
        }

        p.f-18 {
            font-size: 18px;
            line-height: 26px;
        }

        .f-11 {
            font-size: 11px;
            line-height: initial;
        }

        .sub-title {
            margin-bottom: 45px;
        }

        .pic1 {
            position: absolute;
            right: 0;
            top: -40px;
            z-index: 9;
            max-width: 50%;

            img {
                max-width: 100%;
            }
        }

        .pic2 {
            position: absolute;
            top: 610px;
            z-index: 9;
        }

        .pic4 {
            position: absolute;
            right: 0;
            top: 2055px;
            z-index: 9;

            img {
                height: 665px;
            }
        }

        .level1 {
            padding-bottom: 240px;
        }

        .level2 {
            padding-top: 72px;
            padding-bottom: 145px;
            background-image: linear-gradient(#e6f6ff, #f4fbff);

            .container {
                position: relative;
            }

            .line-img {
                img {
                    max-width: 711px;
                }
            }

            .box {
                border-radius: 30px;
                box-shadow: 0 6px 31px -15px rgba(0, 92, 102, 0.7);
                padding: 50px 55px;
                background: #fff;
                max-width: 100%;

                h2 {
                    margin-top: 35px;
                }
            }

            .box1 {
                position: absolute;
                top: -165px;
                max-width: 483px;
                z-index: 99;
            }

            .box2 {
                position: absolute;
                z-index: 99;
                max-width: 483px;
                right: 0px;
                top: 80px;
            }

            .box3 {
                position: absolute;
                top: 390px;
                max-width: 695px;
                z-index: 98;
            }

            .box4 {
                position: absolute;
                z-index: 99;
                max-width: 483px;
                right: 0px;
                bottom: -90px;
            }
        }

        .level3 {
            padding-top: 190px;
            padding-bottom: 120px;

            .list-group {
                li {
                    list-style: none;
                    font-size: 18px;
                    margin-bottom: 21px;

                    .tick-symbol {
                        color: #19b4d1;
                    }
                }
            }

            .l3-left {
                max-width: 85%;
            }

            .l3-right {
                max-width: 83%;
                margin: auto;
                h2 {
                    margin-bottom: 20px;
                    font-size: 28px;
                }

                img {
                    margin-bottom: 21px;
                    max-width: 100%;
                }
            }
        }

        .level4 {
            // background-image: url('/assets/images/how-it-work/desktop-bottom.png');
            background-size: cover;
            min-height: 647px;

            h2 {
                max-width: 666px;
                padding-top: 225px;
                margin: auto;
                color: white;
                font-weight: bold;
                font-size: 56px;
            }

            .find-flight-link {
                display: inline-block;
                font-size: 17px;
                color: #498c00;
                background: #fff;
                padding: 10px 145px;
                margin-top: 47px;
                font-weight: 600;
                border-radius: 4px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .how-it-work-container {
        .pic4 {
            img {
                max-width: 85px;
            }
        }

        > .content {
            .level2 {
                .box4 {
                    bottom: -200px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .how-it-work-container {
        .header-container {
            display: none !important;
        }
    }
}

@media (max-width: 991px) {
    .how-it-work-container {
        .desktop-header {
            display: none;
        }

        > .header {
            // background-image: url('/assets/images/how-it-work/tablet-cover.png');
            .header-content {
                margin-top: 180px;
            }
        }

        > .content {
            padding-top: 60px;

            .pic2 {
                top: 885px;
            }

            .pic4 {
                display: none;
            }

            .container {
                max-width: 100%;
            }

            .level1 {
                padding-left: 25px;
                p {
                    font-size: 18px;
                    max-width: 330px;
                }
            }

            .level2 {
                padding-bottom: 0px;

                .container {
                    min-height: 1275px;
                }

                .line-img {
                    display: none;
                }

                .box {
                    padding: 40px 55px 40px;
                }

                .box1 {
                    left: 0;
                    top: -215px;
                    border-radius: 0 30px 30px 0;
                }

                .box2 {
                    top: 70px;
                    border-radius: 30px 0px 0px 30px;
                }

                .box3 {
                    left: 0;
                    top: 330px;
                    max-width: 625px;
                    border-radius: 0 30px 30px 0;

                    p {
                        font-size: 18px;
                    }
                }

                .box4 {
                    bottom: 50px;
                    border-radius: 30px 0px 0px 30px;
                }
            }

            .level3 {
                padding-top: 75px;
                padding-bottom: 100px;

                .l3-left {
                    max-width: 486px;
                    margin: auto;

                    h2 {
                        max-width: 438px;
                    }
                }

                .l3-right {
                    padding-top: 75px;

                    p {
                        max-width: 450px;
                        margin: auto;
                    }
                }
            }

            .level4 {
                // background-image: url('/assets/images/how-it-work/tablet-bottom.png');
            }
        }
    }
}

@media (min-width: 768px) {
    .how-it-work-container {
        .pic-1-2-mobile {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .how-it-work-container {
        overflow: hidden;

        > .header {
            // background-image: url('/assets/images/how-it-work/mobile-cover.png');
            .header-content {
                margin-top: 80px;
                .title {
                    font-size: 32px;
                }

                .sub-title {
                    font-size: 18px;
                }
            }
        }

        > .content {
            .pic1 {
                display: none;
            }

            .pic2 {
                display: none;
            }

            .level1 {
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 0px;

                h2 {
                    font-size: 22px;
                }
            }

            .pic-1-2-mobile {
                margin-top: -50px;

                img {
                    width: 100%;
                }
            }

            .level2 {
                margin-top: -250px;
                padding-bottom: 35px;

                .box {
                    position: static !important;
                    border-radius: 30px !important;
                    margin-bottom: 20px;
                    padding: 20px;

                    h2 {
                        font-size: 22px !important;
                    }

                    p {
                        font-size: 16px !important;
                        line-height: 26px !important;
                    }
                }

                .box1 {
                    margin-top: -345px;
                }
            }

            .level3 {
                padding-left: 20px;
                padding-right: 20px;

                h2 {
                    font-size: 22px !important;
                }
            }

            .level4 {
                // background-image: url('/assets/images/how-it-work/mobile-bottom.png');
                h2 {
                    font-size: 32px;
                    max-width: 340px;
                    padding-top: 195px;
                }

                .find-flight-link {
                    padding: 11px 30px;
                }
            }
        }
    }
}
