.CheckoutForm {
    .CheckoutForm__disclaimer {
        font-size: 12px;
        color: #5f5f5f;
    }
}

.checkout {
    padding-top: 30px;

    p {
        color: #47495f;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .breadcrumb a {
        color: #000000 !important;
    }

    label {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .DayPicker {
        margin-top: 10px !important;
    }

    .DateRangePicker__dateInput {
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #c6c6c6;
        padding: 13px 20px;
        font-size: 15px;
        display: flex;
        flex-direction: none;

        .fa-angle-down {
            font-size: 20px;
            margin-top: 3px;
        }

        .DateRangePicker__label {
            margin-right: 10px;
            font-weight: 600;
            color: #47495f;
        }

        .DateRangePicker__dateInputValue {
            flex: 1 0 0%;
        }
    }

    .checkout-login-form {
        button {
            background: linear-gradient(98.59deg, #19b4d1 10.67%, #31aec6 97.17%);
            border-radius: 8px;
            color: white;
            font-size: 16px;
            font-weight: normal;
            line-height: 23px;
            border: none;
            padding: 13px 30px;
            margin-bottom: 10px;
            transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
                transform 0.1s ease 0s !important;
        }

        .forgot-password {
            font-weight: 600 !important;
            color: black !important;
            text-decoration: underline !important;
        }

        .select-date .title {
            margin-right: 10px;
            font-size: 15px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #47495f;
        }
    }

    .card-content {
        width: 100%;

        #field-wrapper {
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            min-height: 35px;
            position: relative;
            justify-content: space-between;

            img {
                -webkit-box-flex: 0;
                -ms-flex-positive: 0;
                flex-grow: 0;
                -ms-flex-negative: 0;
                flex-shrink: 0;
                -ms-flex-preferred-size: 22px;
                flex-basis: 22px;
            }

            label {
                margin-bottom: 0;
                font-size: 1rem;

                &:nth-of-type(1) {
                    -webkit-box-flex: 0;
                    -ms-flex-positive: 0;
                    flex-grow: 0;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                    -ms-flex-preferred-size: calc(100% - 160px - 22px);
                    flex-basis: calc(100% - 160px - 22px);
                }

                &:nth-of-type(2) {
                    -webkit-box-flex: 0;
                    -ms-flex-positive: 0;
                    flex-grow: 0;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                    -ms-flex-preferred-size: 100px;
                    flex-basis: 80px;
                }

                &:nth-of-type(3) {
                    -webkit-box-flex: 0;
                    -ms-flex-positive: 0;
                    flex-grow: 0;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                    -ms-flex-preferred-size: 80px;
                    flex-basis: 80px;
                }
            }
        }
    }

    .checkout-section {
        margin-bottom: 30px;
        padding: 30px;

        h3 {
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 30px;
        }

        h5 {
            margin-bottom: 15px;
            font-weight: 600;
            font-size: 18px;
        }

        p {
            margin-bottom: 30px;
        }
    }

    .checkout-sidebar-box {
        padding: 20px 0;

        td {
            padding: 10px;

            &:first-child {
                width: 100px;
                padding-left: 0px;
                border-right: 1px solid #dee2e6;
            }

            &:last-child {
                font-weight: 600;
            }
        }
    }

    .payments-row {
        margin-bottom: 30px;
    }

    .method-payment {
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #c6c6c6;
        padding: 13px 20px;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0px;
        }

        .ant-radio-wrapper-checked {
            .ant-radio-checked {
                .ant-radio-inner {
                    background: #58a946;
                    border-color: #58a946;

                    &::after {
                        background-color: #fff;
                        width: 6px;
                        height: 6px;
                        left: 4px;
                        top: 4px;
                    }
                }
            }
        }

        span {
            font-size: 15px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #47495f;
        }

        .bold {
            font-weight: 600;
        }
    }

    .selected-plan {
        border: solid 2px #58a946;
    }

    .card-image {
        margin-bottom: 20px;

        .payment-card {
            width: 250px;
            margin-left: -11px;
        }
        .mcafee-secure {
            width: 100px;
        }
    }

    .title span {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        color: #5f5f5f;
    }

    .total {
        .title {
            font-size: 15px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #47495f;
        }

        .starting {
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #58a946;
            margin-bottom: 0;
        }

        .row {
            padding: 13px 0;
        }

        .price span:last-child {
            font-size: 18px;
            font-weight: 600;
            text-align: right;
            color: #191b37;
        }

        .price span {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #8b8b8b;
        }

        .repayments span:last-child {
            font-size: 28px;
        }
    }

    .end {
        margin-top: 23px;

        button {
            // background: linear-gradient(98.59deg, #19B4D1 10.67%, #31AEC6 97.17%);
            background: #58a946;
            border-radius: 8px;
            color: white;
            font-size: 18px;
            font-weight: normal;
            line-height: 23px;
            width: 100%;
            border: none;
            padding: 13px 30px;
            margin-bottom: 40px;
            transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
                transform 0.1s ease 0s !important;
        }

        .agree {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            text-align: center;
            color: #5f5f5f;
            margin-bottom: 25px;

            a {
                color: #5f5f5f;
                text-decoration: underline;
            }
        }

        .speak {
            display: block;
            margin-bottom: 29px;
        }

        .things {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #191b37;
            margin-bottom: 14px;
        }

        span {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.63;
            color: #757787;
        }
    }
}

.checkout-feature-wrapper {
    .checkout-feature-header {
        text-align: center;
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 20px;
    }

    .checkout-tids {
        display: block;
        width: 150px;
        height: auto;
        margin: 0 auto;
    }

    .checkout-feature {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        p {
            margin-bottom: 0px;
        }

        img {
            max-width: 100%;
            margin-right: 20px;
            width: 40px;
        }

        h5 {
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.coupon-cta {
    color: $primary;
    cursor: pointer;
    font-weight: 600;
}

.coupon {
    .form-inline {
        align-items: flex-start;
    }

    .form-inline .form-group {
        flex: 1 0 auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        input {
            width: 100%;
        }
    }

    button {
        background: linear-gradient(98.59deg, #19b4d1 10.67%, #31aec6 97.17%);
        border-radius: 4px;
        color: white;
        font-weight: normal;
        border: none;
        padding: 7px 50px;
        margin-left: 9px;
        transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
            transform 0.1s ease 0s !important;
    }
}

.discount {
    padding-bottom: 0px !important;

    .title,
    .price span {
        color: darkgrey !important;
    }

    .title svg {
        margin-left: 5px;
        cursor: pointer;

        &:hover {
            color: black;
        }
    }
}

.price-before-discount {
    margin-right: 5px;
    text-decoration: line-through;
}

@include media-breakpoint-down(sm) {
    .checkout-sidebar {
        order: -1;
    }

    .checkout .checkout-sidebar-box {
        background-color: white;
        -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
        border-radius: 8px !important;
        border: none !important;
        margin-bottom: 30px;
        padding: 30px 30px 20px;

        h4 {
            font-weight: bold;
            font-size: 24px;
        }
        p {
            margin-bottom: 20px;
        }
    }
}
