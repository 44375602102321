.content-date {
    min-height: 45px;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    padding: 10px 20px;
    width: 100%;

    p {
        margin: 0;
        color: #757787;
    }
    .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__input-container {
            width: 100%;
            input {
                width: 100%;
                padding: 0;
                font-size: 16px;
                border: none;
                height: auto !important;
            }
        }
    }
    .react-datepicker-popper {
        right: 10px;
        top: 2px !important;
        left: auto !important;
        .react-datepicker {
            margin-top: 2px;
        }
        .react-datepicker__day--in-range,
        .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
            background: rgba(88, 169, 70, 0.2);
            color: #d1d1d1;
        }
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected,
        .react-datepicker__day--range-end,
        .react-datepicker__day--range-start {
            background: #58a946 !important;
        }
        .react-datepicker__day--range-start {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-right: 0;
            width: calc(1.7rem + 0.166rem);
            color: #fff;
        }
        .react-datepicker__day--range-end {
            margin-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            width: calc(1.7rem + 0.166rem);
            color: #fff;
        }
        .react-datepicker__day--in-range:not(.react-datepicker__day--range-end):not(
                .react-datepicker__day--range-start
            ) {
            margin-left: 0;
            margin-right: 0;
            border-radius: 0;
            width: calc(1.7rem + 2 * 0.166rem);
        }
        .react-datepicker__current-month {
            color: #434b55;
            font-size: 13px;
            font-weight: 300;
        }
        .react-datepicker__triangle {
            display: none;
        }
        .react-datepicker__header {
            background: #fff;
            border: none;
        }
        .react-datepicker__day--outside-month {
            color: #c4cdd5;
        }
        .react-datepicker__day-names {
            border-top: 1px solid #dfe4e8;
            border-bottom: 1px solid #dfe4e8;
            margin-top: 20px;
            .react-datepicker__day-name {
                color: #8b8b8b;
            }
        }
    }
    .not-show-keyboard {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.dateRange:focus ~ .content-date {
    border: 1px solid #58a946;
}

.focus {
    border: 2px solid #58a946;
}
