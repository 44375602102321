.Home {
    .Home__hero {
        min-height: 550px;
        // background-image: url('/assets/images/hero-images/palmtrees.jpg');
        background-size: cover;
        background-position: center top;
        padding: 160px 30px 60px;

        h1 {
            text-align: center;
            color: #ffffff;
            margin-bottom: 10px;
            font-size: 56px;
        }

        p {
            font-size: 24px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.42;

            text-align: center;
            color: #ffffff;
            margin-bottom: 30px;
        }
    }

    .HomeTestimonials {
        background: linear-gradient(180deg, #effaff, #dff4fe);
        padding-bottom: 100px;

        h2 {
            font-size: 50px;
        }

        h4 {
            color: #9bacaf;
            text-transform: uppercase;
            font-size: 16px;
        }

        .col-md-8 {
            // background-image: url('/assets/images/dots-80-opacity.png');
            background-size: 560px;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .HomeTestimonials__carousel {
        overflow: visible;
        margin: 20px 0 50px;

        .react-multiple-carousel__arrow--left {
            left: calc(-8% + 1px);
        }
        .react-multiple-carousel__arrow--right {
            right: calc(-8% + 1px);
        }

        .react-multi-carousel-track {
            align-items: stretch;
        }
        .react-multi-carousel-item {
            transition: all 0.4s;
            width: 100%;
        }
        .react-multi-carousel-item:not(.react-multi-carousel-item--active) {
            opacity: 0.3;
        }

        .HomeTestimonials__card {
            width: calc(100% - 40px);
            height: 100%;
            // margin-left: 30px;
            margin: 0 0 0 20px;

            p:first-child {
                margin-bottom: 5px;
            }

            // background: white;
            // padding: 48px;
            // border-radius: 12px;
            // -webkit-box-shadow:rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 76px !important;
            // box-shadow:rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 76px !important;
            // margin-bottom: 40px;

            // p {
            //     font-weight: 700;
            //     color: black;
            // }

            .HomeTestimonials__cardContent {
                letter-spacing: -0.01em;
                font-size: 20px;
                line-height: 30px;
            }
            .HomeTestimonials__location {
                color: grey;
                font-weight: normal;
            }
        }
    }

    .HomeTestimonials__card {
        z-index: 1;
        width: 350px;
        background: white;
        padding: 48px;
        border-radius: 12px;
        -webkit-box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 76px !important;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 76px !important;
        margin-bottom: 40px;

        p {
            font-weight: 700;
            color: black;
        }

        .HomeTestimonials__cardContent {
            letter-spacing: -0.042em;
            font-size: 24px;
            line-height: 38px;
            margin-bottom: 0px;
            font-weight: 400;
        }
    }

    .HomeFlights {
        padding-top: 60px;
        padding-bottom: 40px !important;
    }

    .Home__section {
        padding-bottom: 80px;
        overflow: hidden;

        p {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            margin-bottom: 24px;
            color: #47495f;
        }

        .Home__section__button {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .Home__section__learnMore {
                margin-left: 25px;
                font-size: 16px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.wistia_responsive_padding {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 65px -15px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 55px 0px !important;
    // border-radius: 4px;
    // overflow: hidden;
}

mark {
    padding: 0px !important;
    background-color: transparent !important;
    font-weight: 600 !important;
    color: #19b4d0 !important;
}

.rbt-input-hint input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: -2px !important;
}

.good-hand {
    // min-height: 147px;
    background-image: linear-gradient(276deg, #e6f6ff, #f4fbff);
    padding: 26px 0;

    .container {
        text-align: center;

        .content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            > div {
                &:last-child {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 70.72px;
                    flex: 0 0 70.72px;
                }
            }
        }

        p {
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;

            color: #9b9b9b;
            margin-bottom: 6px;
            text-align: center;
        }

        img {
            width: 441px;
        }
    }
}

.stays {
    padding-bottom: 100px;
    padding-top: 80px;
    background: white;

    h2 {
        margin-bottom: 40px;
    }

    h4 {
        color: #9bacaf;
        text-transform: uppercase;
        font-size: 16px;
    }

    .stays__cardWrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .btn {
        margin-top: 40px;
    }

    .stays__card {
        justify-content: center;
        flex: 0 1 250px;
        margin: 5px;
        padding: 140px 10px;
        text-align: center;
        cursor: pointer;
        transition: all 0.4s;
        background-size: cover;
        background-position: center center;

        &:hover {
            opacity: 0.9;
        }

        h4 {
            text-transform: none !important;
            color: white;
            font-size: 1.9rem;
            font-weight: 900;
            margin-bottom: 0px;
            transition: all 0.4s;
        }

        p {
            color: white;
            transition: all 0.4s;
        }

        // &--byron-bay {
        //     background-image: url('/assets/images/stays/byronbay.png');
        // }

        // &--cairns {
        //     background-image: url('/assets/images/stays/cairns.jpg');
        // }

        // &--sydney {
        //     background-image: url('/assets/images/stays/sydney.jpg');
        // }

        // &--whitsundays {
        //     background-image: url('/assets/images/stays/whitsundays.jpg');
        // }

        // &--goldcoast {
        //     background-image: url('/assets/images/stays/goldcoast.jpg');
        // }
    }
}

@include media-breakpoint-down(sm) {
    .Home {
        .Home__hero {
            padding: 40px 0px;

            h1 {
                font-size: 28px;
            }

            p {
                font-size: 16px;
                padding: 0px 40px;
            }

            .container {
                padding: 0;
            }
        }

        .Home__section {
            .Home__section__button {
                .Home__section__learnMore {
                    margin-left: 0px;
                }
            }
        }
    }

    .HomeTestimonials {
        .col-md-3 {
            order: -1;
        }
    }

    .HomeFlights .col-md-6 {
        overflow: hidden;
        order: -1;
    }

    .HomeFlights {
        padding-top: 10px !important;
    }

    .Home__section__button {
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        .Home__section__learnMore {
            margin: 15px 0 0;
        }
    }

    .HomeFlights__mockup {
        max-width: 130% !important;
    }
}

.HomeTestimonials__us {
    overflow: hidden;
    padding-top: 60px;
}

@include media-breakpoint-down(lg) {
    .HomeTestimonials {
        .HomeTestimonials__card {
            width: 300px;

            &:nth-child(2) {
                left: 245px;
            }
        }

        .HomeTestimonials__cardContent {
            font-size: 20px !important;
            line-height: 28px !important;
        }
    }
}

@include media-breakpoint-down(sm) {
    .HomeTestimonials__carousel {
        .HomeTestimonials__card {
            width: calc(100% - 10px);
            margin: 0 0 0 5px;
        }
        .react-multiple-carousel__arrow {
            border-radius: 0px;
        }

        .react-multiple-carousel__arrow--left {
            left: -10px !important;
        }
        .react-multiple-carousel__arrow--right {
            right: -10px !important;
        }
    }
    .find-us-on-facebook-wrapper {
        a {
            display: flex;
            flex-direction: column !important;
            align-items: center;

            img:last-child {
                margin-left: -7px;
            }
        }
    }

    .find-us-on-facebook {
        margin-right: 0px !important;
        margin-bottom: 20px !important;
    }
}

@include media-breakpoint-down(md) {
    .Home {
        h2 {
            font-size: 29px !important;
        }
    }

    .HomeTestimonials {
        padding-bottom: 60px !important;

        .col-md-8 {
            background-image: none !important;
        }
        .HomeTestimonials__card {
            width: 100%;
            position: static !important;
            margin-left: 0 !important;
            margin-bottom: 20px !important;
        }

        .col-md-3 {
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .stays {
        .stays__cardScrollWrapper {
            height: 100% !important;
            width: 100% !important;
            overflow-y: hidden !important;
        }
        .stays__cardWrapper {
            justify-content: flex-start;
            overflow-x: auto;
            height: 100% !important;
            overflow-y: auto !important;
            white-space: nowrap !important;
            overflow-x: scroll !important;
            padding-bottom: 3rem !important;
            margin-bottom: -3rem !important;
        }
        .stays__card {
            flex-shrink: 0;

            &:first-child {
                margin-left: 10px;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .HomeTestimonials__card {
        &:nth-child(2) {
            position: relative;
            margin-top: -235px;
            left: 325px;
            z-index: 2;
        }

        &:nth-child(3) {
            margin-left: 60px;
            position: relative;
            top: -206px;
            right: 55px;
        }
    }
}
