$btnBg: linear-gradient(135deg, #ff934b 0%, #ff5e62 100%);
$btnActive: #6c757d;
$bgSecondary: linear-gradient(135deg, #0cc5b7 0%, #2bd891 100%);
$bgPrimary: #effaff;

$primary: #19b4d1;
$dark: #212529;
$info: #17a2b8;
$success: #28a745;
$grayDark: #343a40;
$gray: #6c757d;
$teal: #20c997;
$pink: #e83e8c;
$red: #e83e3e;
$orange: #ff9800;
