.ChangeFlights {
    margin-bottom: 200px;
    .ChangeFlights__header {
        padding-top: 20px;
        // background: white;
        position: relative;
        // box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.18) !important;
    }

    .ChangeFlights__confirmFlyOver {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: white;
        padding: 30px 0;
        z-index: 3;
        bottom: -280px;
        transition: all 0.3s;

        &.open {
            bottom: 0;
            -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 6px 35px !important;
            box-shadow: rgba(0, 0, 0, 0.8) 0px 6px 35px !important;
        }
    }

    .ChangeFlights__cancelSelection {
        font-size: 14px;
        color: $primary;
        font-weight: 600;
        cursor: pointer;
    }

    .ChangeFlights__feesSummary {
        // display: flex;
        // flex-direction: column;
        // align-items: flex-end;
        // flex: 1 0 0%;
        // border: 1px solid rgba(0,0,0,0.2)!important;
        // padding: 10px 20px;
        // border-radius: 8px;
        margin: 20px 0;
    }

    .ChangeFlights__fees {
        display: flex;
        align-items: flex-start;

        .ChangeFlights__pay {
            flex: 2 0 0%;
            padding-right: 80px;

            p {
                font-size: 14px;
                font-weight: 600;
            }
        }

        button {
            font-weight: 600;

            svg {
                margin-right: 7px;
            }
        }

        .ChangeFlights__confirmButton {
            height: 50px;
            width: 240px;
            text-align: center;
        }

        .btn {
        }

        td:first-child {
            padding-right: 10px;
        }
        td:last-child {
            text-align: right;
        }

        h4 {
            font-weight: bold;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .search-result .btn-select {
        height: 46px;
        width: 115px;
    }

    .page-content.selected {
        .filters-wrapper {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    .footer {
        display: none !important;
    }

    .FeeTable {
        tr:first-child {
            td {
                border-top: none;
            }
        }

        .table-footer {
            font-weight: bold;
            color: black;
            td {
                border-bottom: none;
            }
        }
    }

    .content-search-result {
        &.selected {
            .search-result-box:not(.selected) {
                box-shadow: none !important;
            }

            .search-result-box {
                opacity: 0.4;
                height: 0px;
                margin-bottom: 0px;

                &:hover {
                    opacity: 1;
                }

                &.selected {
                    opacity: 1;
                    height: auto;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.Modal__changeFlights {
    .ChangeFlights__feesSummary {
        margin: 20px 0;
    }
}

@include media-breakpoint-up(md) {
    .ChangeFlights {
        .EditSearch .close {
            display: none;
        }
    }
}
