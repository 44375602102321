.content-passenger {
    .right-content {
        background-color: #f4fbff;
        .container {
            padding-top: 38px;
            > h3 {
                font-size: 32px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;

                color: #191b37;
                margin-bottom: 12px;
            }
            > p {
                font-size: 18px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.44;

                color: #47495f;
                margin-bottom: 27px;
            }
            .content {
                .form {
                    > p {
                        font-size: 20px;
                        font-weight: 600;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;

                        color: #000000;
                        margin-bottom: 24px;
                    }
                    form {
                        border-radius: 8px;
                        -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                        background-color: #ffffff;
                        padding: 30px;
                        margin-bottom: 20px;
                        label {
                            font-size: 15px;
                            font-weight: 600;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;

                            color: #47495f;
                        }
                        .date-content {
                            height: 38px;
                            border: 1px solid #c6c6c6;
                            padding: 6px 12px;
                            border-radius: 0.25rem;
                        }
                    }
                }
                .contact-detail {
                    > p {
                        margin-bottom: 7px;
                    }
                    > span {
                        font-size: 18px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;

                        color: #757787;
                        margin-bottom: 18px;
                        display: block;
                    }
                    .forgot-password {
                        font-size: 15px;
                        font-weight: 600;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;

                        margin-bottom: 15px;
                        display: block;
                        color: $primary;
                    }
                }
            }
            .react-tel-input {
                input {
                    width: 100%;
                }
            }
            .btn-submit {
                border-radius: 4px; // background-image: linear-gradient(86deg, #19b4d1, #7fd3e3);
                background: #58a946;
                font-size: 17px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;

                text-align: center;
                width: 100%;
                margin-bottom: 100px;
                color: #ffffff;
            }
        }
    }
}

@media (max-width: 450px) {
    .content-passenger {
        .content {
            padding: 0px !important;
        }
        .right-content {
            padding: 15px;
            .container {
                padding: 0;
            }
        }
    }
}
