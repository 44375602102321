.hotels-slider {
    background: black;

    .slide {
        transition: opacity 0.4s;

        &:not(.selected) {
            opacity: 0.3;
        }
    }

    .hotels-slider__slide-inner {
        width: 100%;
        min-height: 650px;
        background-size: cover;
        background-position: center center;
    }
}

.HotelTags {
    display: flex;
    flex-direction: row;

    .HotelTags__tag {
        color: black;
        height: 1.25rem;
        line-height: 1.125rem;
        text-transform: uppercase;
        font-weight: 600;

        white-space: nowrap;
        display: inline-block;
        border-radius: 0.125rem;
        border-width: 1px;
        border-style: solid;

        // green
        // background-color: rgb(204, 236, 238);
        // border-color: rgb(0, 163, 173);

        // red
        background-color: rgb(255, 234, 234);
        border-color: rgb(255, 51, 51);

        border-image: initial;
        padding: 0px 0.4375rem;
        margin-right: 7px;
        font-size: 12px;
    }
}

.hotels-offers-card__slider,
.hotel-result-box__slider {
    .carousel,
    .slider-wrapper,
    .slider,
    .slide,
    .hotels-offer-card__slider-inner {
        height: 100% !important;
    }
}

// .hotels-offers-card__slider {
//     height: 100%;
// }

// .hotels-offer-card__images {
//     background: black;
// }

.hotels-offer-card {
    // padding: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    svg {
        color: $primary;
    }

    .room-code {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
        margin-bottom: 10px;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .hotels-offer-card__images {
        flex: 0 0 280px;
    }

    .hotels-offer-card__description {
        flex: 1 0;
        padding: 20px;

        h4 {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .hotels-offer-card__pricing {
        flex: 0 0 200px;
        padding: 20px;
        text-align: center;

        p {
            font-size: 12px;
            margin-bottom: 5px;
        }

        .title {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;

            color: #757787;
            margin-bottom: 3px;
        }

        .pw {
            font-size: 23px;
            font-weight: 800;
            color: #353d74;
            letter-spacing: -0.06em;

            sup {
                font-size: 10px;
                font-weight: 100;
                top: -15px;
            }
        }
        .total {
            letter-spacing: -0.03em;
        }
    }

    .hotels-offer-card__slider-inner {
        width: 100%;
        min-height: 200px;
        background-size: cover;
        background-position: center center;
    }

    .btn-search {
        background: #58a946;
        border-radius: 8px;
        color: white;
        font-size: 16px;
        font-weight: normal;
        line-height: 23px;
        border: none;
        // padding: 13px 30px;
        padding: 10px 20px;
        margin-top: 10px;
        transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
            transform 0.1s ease 0s !important;
    }
}

.hotel-result-call-out {
    color: $green;
    margin-top: 10px;
    font-size: 14px;
}

.hotels-offer-card {
    .hotel-result-call-out {
        margin-top: 0px;
        margin-bottom: 10px;
    }
}

.hotel-details {
    background: white;
    padding: 40px 0;

    .hotel-header .loading-shimmer {
        opacity: 0.4;
        border-radius: 8px;
    }

    .hotel-header__heading {
        display: flex;
        flex-direction: row;

        .hotel-header__logo {
            flex: 0 0 70px;
            img {
                max-width: 100%;
            }
        }
        h1 {
            flex: 1 0;
        }
    }

    .HotelDetails__searchForm {
        padding: 30px;
        display: flex;
        flex-direction: column;

        .DayPicker {
            right: 0;
        }

        .DateRangePicker__dates,
        .dropdown-passengers {
            margin-bottom: 10px;
        }

        .btn-search {
            background-color: $primary !important;
        }

        .fields {
            p {
                font-size: 12px;
                margin-bottom: 5px;
            }

            input {
                margin-bottom: 10px;
            }

            display: flex;
            flex-direction: column;
            color: rgb(34, 34, 34) !important;
            font-size: 16px;
        }
    }
}

.hotel-offers {
    padding-top: 40px;
    position: relative;
    border-top: 1px solid whitesmoke;

    h3 {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 40px;
    }

    .content-search-result.dummy:before {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.hotel-detail-page {
    .hotel-detail-loading-images {
        .loading-shimmer {
            margin-bottom: 0px;
            min-height: 650px;
            opacity: 0.8 !important;
        }
    }

    .HotelTags {
        margin-bottom: 10px;
    }

    h1 {
        font-size: 30px;
        font-weight: 700;
    }
    hr {
        margin: 1.5rem 0;
    }

    .hotel-read-more {
        font-weight: 600 !important;
        color: black !important;
        text-decoration: underline !important;
        font-size: 14px !important;
    }

    .hotel-header__meta {
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;

        text-decoration: underline;
        flex-direction: column;
        display: flex;

        .stars {
            color: $primary;
            margin-bottom: 10px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .hotels-slider {
        // background: black;

        // .slide {
        //     transition: opacity .4s;

        //     &:not(.selected) {
        //         opacity: 0.3;
        //     }
        // }

        .hotels-slider__slide-inner {
            // width: 100%;
            min-height: 300px;
            // background-size: cover;
            // background-position: center center;
        }
    }

    .hotel-detail-loading-images {
        padding: 0px !important;
        .loading-shimmer {
            min-height: 300px !important;
        }
    }

    .hotels-offer-card {
        // padding: 30px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        svg {
            color: $primary;
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .hotels-offer-card__images {
            flex: 0 0 auto;
        }

        .hotels-offer-card__description {
            padding-bottom: 0px;

            h4 {
                font-size: 20px;
                font-weight: bold;
            }
        }

        .hotels-offer-card__pricing {
            flex: 0 0 auto;
            // padding-top: 0px;
            text-align: center;
            background: #bfd3e4;

            p {
                font-size: 12px;
                margin-bottom: 5px;
            }

            .title {
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;

                color: #757787;
                margin-bottom: 3px;
            }

            .pw {
                font-size: 23px;
                font-weight: 800;
                color: #353d74;
                letter-spacing: -0.06em;

                sup {
                    font-size: 10px;
                    font-weight: 100;
                    top: -15px;
                }
            }
        }

        // .hotels-offer-card__slider-inner {
        //     width: 100%;
        //     height: 200px;
        //     background-size: cover;
        //     background-position: center center;
        // }
    }
}
