.search-info {
    p:not(.search-info__airport-codes) svg {
        color: rgb(178, 177, 177);
        font-size: 12px;
        margin-right: 10px;
    }

    .edit-m {
        display: none;
    }
    .ico-search {
        margin-top: 20px;
        margin-bottom: 19px;
    }
    .ico-user {
        margin-top: -2px;
    }
    p {
        margin-bottom: 14px;
        span {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #47495f;
        }
        span.search-info__airport-codes__multi-city-flights {
            font-size: 14px !important;
            font-weight: normal !important;
            margin-left: 5px;
        }
        img {
            margin-right: 10px;
        }
    }

    &__airport-codes {
        span {
            font-size: 16px !important;
            font-weight: 600 !important;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            color: #47495f;
        }

        img {
            margin: 0 5px;
        }
    }
}

@media (max-width: 776px) {
    .search-info {
        .edit {
            display: none;
        }
        .edit-m {
            display: inline-block;
        }
        > div {
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-bottom: 16px;
            &:first-child {
                margin: 12px 0;
            }
            p {
                -ms-flex-preferred-size: calc(50% - 15px);
                flex-basis: calc(50% - 15px);
                margin: 0;
            }
        }
    }
}
