.Header {
    align-items: stretch;

    .Header__logo {
        margin-right: 0px;
        flex: 1 0 240px;
        display: flex;
        z-index: 4;
        flex-direction: row;

        .back-to-results {
            color: $primary;
            padding-top: 14px;
            a {
                cursor: pointer;
            }
        }
    }

    .navbar-nav {
        align-items: center;
    }

    .navbar-collapse {
        flex: 1 0 240px;
        justify-content: flex-end;
    }

    .container-fluid {
        justify-content: center;
        align-items: baseline;
    }

    .dropdown-menu {
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
        border: none;
        margin-top: 10px;
        padding: 15px 0;
        width: 220px;

        .dropdown-item {
            font-weight: 400;
            color: #404040;
            font-size: 14px;
            padding: 0.6rem 1.5rem;
            background-color: white;

            &.refer-nav-link {
                color: $primary !important;

                &:hover {
                    color: $primary !important;
                }
            }

            &:hover {
                color: #404040 !important;
                text-decoration: none;
                background-color: #f8f9fa;
            }

            &.active,
            &:active {
                color: #404040;
                background-color: white;

                &:hover {
                    background-color: #f8f9fa !important;
                }
            }
        }

        .dropdown-item__primary-wrapper {
            .dropdown-item {
                font-weight: 600;
            }
        }
    }

    .back-to-results {
        // color: $primary;
        font-weight: 600;
        flex: 1 1;
        a {
            cursor: pointer;
        }
    }
}

.edit-search-overlay {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    z-index: -2;
    transition: all 0.2s;
    pointer-events: none;

    &.active {
        background: rgba(0, 0, 0, 0.35);
        pointer-events: auto;
    }
}

.modal-backdrop.show {
    opacity: 0.6;
}

.login-modal {
    &.modal-dialog {
        max-width: 400px;
    }

    .modal-content {
        border-radius: 12px;
        border: none;
    }

    .modal-header {
        display: block;
        text-align: center;
        padding: 0.7rem 1rem;
        position: relative;

        .modal-title {
            font-weight: 600;
            font-size: 16px;
        }

        .close {
            position: absolute;
            left: 5px;
            top: 1px;
            margin-top: 0px;
            padding: 10px;
        }
    }

    .modal-body {
        padding: 1.8rem;
    }
}

.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    border: none;
    outline: none;
}

.hamburger:hover {
    opacity: 0.7;
}
.hamburger:focus {
    outline: none;
}

.hamburger.is-active:hover {
    opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #b7bfc6;
}

.hamburger-box {
    width: 20px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: #848a90;
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}
.hamburger-inner::before {
    top: -10px;
}
.hamburger-inner::after {
    bottom: -10px;
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.logo {
    width: 135px;
    cursor: pointer;
}

@include media-breakpoint-down(md) {
    .Header {
        .dropdown-menu {
            width: 100% !important;
        }
    }
}

@include media-breakpoint-up(md) {
    .Header {
        .header__button,
        .header__button.nav-link {
            padding: 7px 20px !important;
            border-radius: 30px;
            border-width: 2px;
            border-style: solid;
            border-color: white;
            background: transparent;
            color: #484848;
            outline: none;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.18);
            font-weight: 600;
            margin-left: 20px;

            .dropdown-toggle {
                padding: 0 !important;
            }
        }
        &.transparent {
            .header__button {
                color: white;
                box-shadow: none;
                border-width: 2px;
            }
        }
    }
}

.navbar-collapse {
    align-items: flex-start;
    padding-top: 7px;
}

@include media-breakpoint-down(sm) {
    .Header {
        .navbar-nav {
            align-items: stretch;
        }
    }

    // .navbar {
    //     z-index:9997;
    // }
    .navbar-nav {
        padding: 20px 20px 40px 20px !important;
    }
    .nav-item .nav-link svg {
        font-size: 22px;
    }

    .navbar-expand-md .navbar-collapse.collapse,
    .collapsing {
        position: absolute !important;
        top: 0;
        margin-left: -1rem;
        margin-right: -1rem;
        width: 100%;
        padding-top: 68px;
        background: white;
        z-index: -1;
    }
    .navbar-collapse:not(.show) li {
        opacity: 0;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    .navbar-collapse.show li:nth-child(1) {
        -webkit-animation: fadeIn 0.2s 1;
        animation: fadeIn 0.2s 1;
    }
    .navbar-collapse.show li:nth-child(2) {
        -webkit-animation: fadeIn 1s 1;
        animation: fadeIn 1s 1;
    }
    .navbar-collapse.show li:nth-child(3) {
        -webkit-animation: fadeIn 1s 1;
        animation: fadeIn 1.8s 1;
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .navbar a.nav-link {
        display: block;
        padding: 10px 16px !important;
        font-size: 20px;
        color: #424042;
        border-bottom: 1px solid rgb(230, 230, 230);
    }
    .navbar li:first-child a.nav-link {
        border-top: 1px solid rgb(230, 230, 230);
    }

    .overlay {
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        content: "";
        z-index: -2;
        transition: all 0.4s;
        pointer-events: none;
    }
    .navbar-collapse.show + .overlay,
    .collapsing + .overlay {
        background: rgba(0, 0, 0, 0.6);
        pointer-events: auto;
    }
}
