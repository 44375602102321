.term-content {
    padding: 40px 0;
    padding-top: 40px;

    .title {
        color: #444;
        letter-spacing: 0;
        -webkit-font-smoothing: antialiased;
        font-size: 35px;
        line-height: 42px;
        margin-bottom: 7px;
        font-weight: 900;
    }

    .last-update {
        line-height: 1.6;
        padding-bottom: 27px;
        -webkit-font-smoothing: subpixel-antialiased;
        color: #676767;
    }

    .block-content {
        h2 {
            color: #2eaacc;
            letter-spacing: 0;
            -webkit-font-smoothing: antialiased;
            font-weight: 900;
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 20px;
            margin-top: 30px;
        }
        h3 {
            color: #444;
            font-weight: 900;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 20px;
        }
        h4 {
            color: #2eaacd;
            font-size: 20px;
            text-decoration: underline;
            font-style: italic;
            margin-bottom: 20px;
            line-height: 22px;
        }

        p,
        li {
            line-height: 1.6;
            padding-bottom: 0;
            -webkit-font-smoothing: subpixel-antialiased;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
        }

        ol {
            li {
                margin-bottom: 20px;
            }
        }

        ul {
            margin-left: 30px;
            margin-bottom: 30px;
            padding-left: 0px;
            list-style: none;

            li {
                list-style: disc;
                list-style-position: outside;
                // color: #676767;
                // font-size: 18px;
            }
        }
    }
}

@media (max-width: 531px) {
    .term-content {
        .title {
            font-size: 42px;
        }
        .container {
            overflow: hidden;
            .text-center {
                img {
                    width: 100%;
                }
            }
        }
    }
}
