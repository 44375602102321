.CardDetails {
    display: flex;
    align-items: center;
    font-size: 12px;

    > span {
        margin-right: 10px;
        display: flex;
        align-items: center;
    }

    .CardDetails__image {
        border-radius: 4px;
        border: 1px solid #cdd6d9;
        width: 45px;
        height: 25px;
        background-size: 77%;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .CardDetails__last4 {
        font-weight: 600;
        color: #000000d9;
    }

    .CardDetails__expiry {
        color: #a4a4a4;
    }
}
