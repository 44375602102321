.FAQ {
    h1 {
        margin: 40px 0 20px;
    }

    h2 {
        font-size: 20px;
        color: #9bacaf;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .FAQ__section {
        margin-bottom: 40px;
    }

    .nav-link {
        &:hover {
            background: #dff0f7;
        }
        &.active {
            box-shadow: inset 3px 0px $primary;
            background: #dff0f7;
        }
    }

    .FAQ__nav {
        margin-top: 20px;
    }
}

@include media-breakpoint-down(sm) {
    .FAQ {
        h1 {
            text-align: center;
        }
    }
}
