body {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.011em;
}
.container {
    max-width: 1140px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #212529;
}

h1,
h2,
h3,
h4 {
    letter-spacing: -0.022em;
}

h1 {
    font-weight: 800;
    font-size: 40px;
}

h2 {
    font-weight: 800;
    font-size: 36px;
}

h3 {
    font-weight: 700;
    font-size: 24px;
}

a {
    color: $primary;
    &:hover {
        color: $primary;
    }
}

hr {
    margin: 0;
}

input,
button {
    outline: none;
    border: none;
}

label,
p {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
}

p.danger {
    color: $red;
}

.breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    .breadcrumb-item {
        font-weight: 600;
        &.active {
            // color: #9CCADF;
            color: #000000;
        }
    }
    a {
        color: $primary !important;
    }
}

.tippy-box {
    background-color: #141525 !important;
    color: white !important;
    .tippy-content {
        padding: 15px 15px !important;
        font-size: 14px !important;
    }
    .tippy-arrow {
        color: #13152d !important;
    }
    &.light {
        background-color: white !important;
        color: #47495f !important;
        font-weight: 600 !important;
        -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.14) 0px 3px 6px !important;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.14) 0px 3px 6px !important;
        .tippy-arrow {
            color: white !important;
        }
    }
    &.light {
        background-color: white !important;
        color: #47495f !important;
        font-weight: 600 !important;
        -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.14) 0px 3px 6px !important;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.14) 0px 3px 6px !important;
        .tippy-arrow {
            color: white !important;
        }
    }
}

.StripeForm {
    .StripeForm__bookNow {
        margin-bottom: 10px;
        min-height: 50px;
    }
}

.StripeCardElement {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 13px 20px;
    border-radius: 8px;
    margin-bottom: 10px;
    background: #ffff;
}

.btn {
    border-radius: 8px;
    font-size: 16px;
    font-weight: normal;
    line-height: 23px;
    border: none;
    padding: 13px 20px;
    transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
        transform 0.1s ease 0s !important;
}

.btn-primary {
    background: linear-gradient(98.59deg, #19b4d1 10.67%, #31aec6 97.17%);
    color: white;
}

.btn-see-through {
    background: linear-gradient(98.59deg, #e8e8e8 10.67%, #f4f4f4 97.17%);
    // color: white;
}

.btn-success {
    background: #58a946;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    border-color: $primary;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    background-color: whitesmoke;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item.active,
.dropdown-item:active {
    color: inherit;
    background-color: whitesmoke;
}

.card {
    background-color: white;
    -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
    border-radius: 8px !important;
    border: none !important;
}

.Content {
    overflow: hidden;
}

.Content {
    overflow: hidden;
}

.page-content {
    background-color: $bgPrimary;
    padding-bottom: 30px;
    h1 {
        font-weight: bold;
    }
}

.badge {
    padding: 7px 15px !important;
    background-color: #dff7fa !important;
    border-radius: 160px !important;
    color: $primary !important;
    font-weight: 600 !important;
    &-success {
        color: #4db821 !important;
        background-color: #e3fadf !important;
    }
    &-primary {
        color: $primary !important;
        background-color: #dff7fa !important;
    }
    &-danger {
        background-color: #efdddf !important;
        color: #dc3545 !important;
    }
}

h2 {
    .plt-tooltip {
        margin-left: 5px;
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: 0px 0;
    }
    100% {
        background-position: 100em 0;
    }
}

.modal {
    .modal-content {
        border-radius: 8px;
        border: none;
    }
    .modal-header {
        padding: 20px 30px;
    }
    .modal-body {
        padding: 30px;
    }
}

.loading-shimmer {
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    height: 80px;
    margin-bottom: 20px;
    padding-top: 50px;
    -webkit-backface-visibility: hidden;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 8px;
    &.shimmer-price {
        animation-duration: 20s;
        width: 100px;
        margin: 0 auto 10px;
    }
    &.shimmer-cta {
        animation-duration: 34s;
        width: 20px;
        height: 10px;
        margin: 0 auto;
    }
}

.checkbox {
    border-radius: 4px;
    label {
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        color: #19b4d1;
        &:before {
            background-color: #fff;
            border: 1px solid #d1d1d1;
            width: 26px;
            height: 26px;
            font-size: 26px;
            top: -0.05rem;
            left: -2.1rem;
        }
        &:after {
            top: -0.05rem;
            left: -2.1rem;
            width: 26px;
            height: 26px;
        }
    }
    input:checked ~ .custom-control-label::before {
        background-image: linear-gradient(45deg, #19b4d1, #7fd3e3);
    }
    input:checked ~ .custom-control-label::after {
        background: #fff;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        top: 7px;
        left: -25px;
    }
}

.update-password-form {
    .input-group {
        margin-bottom: 15px;
    }
    button {
        background: linear-gradient(98.59deg, #19b4d1 10.67%, #31aec6 97.17%);
        border-radius: 8px;
        color: white;
        font-size: 16px;
        font-weight: normal;
        line-height: 23px;
        border: none;
        padding: 13px;
        transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
            transform 0.1s ease 0s !important;
    }
}

.CardsDropdown__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: 10px;
    img {
        width: 150px;
        height: auto;
    }
}

.UpdateCard__toggle {
    color: $primary;
    cursor: pointer;
    font-weight: 600;
}

.BillingAddressForm {
    input {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        font-size: 16px;
        font-weight: normal;
        line-height: 23px;
        padding: 13px 20px;
        color: black;
        margin-bottom: 10px;
    }
}

.PlacesAutocomplete {
    position: relative;
    ul {
        position: absolute;
        background: white;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        padding: 5px 0px;
        z-index: 9999;
        li {
            list-style-type: none;
            padding: 10px 20px;
            &:hover {
                background: whitesmoke;
                cursor: pointer;
            }
        }
        .manual-entry {
            color: $primary;
            font-weight: 600;
            font-size: 12px;
            padding: 5px 20px 10px;
            display: block;
        }
    }
}

.BillingAddressForm__manualInputs {
    select {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        font-size: 16px;
        // font-weight: normal;
        // line-height: 23px;
        padding: 12px 13px;
        color: black;
        margin-bottom: 10px;
        width: calc(50% - 5px);
        &:first-of-type {
            margin-left: 5px !important;
        }
        &:last-of-type {
            margin-right: 5px !important;
        }
    }
    input {
        width: calc(50% - 5px);
        &:first-of-type {
            width: 100%;
        }
        &:nth-child(3),
        &:nth-child(5) {
            margin-right: 5px;
        }
        &:nth-child(4),
        &:nth-child(6) {
            margin-left: 5px;
        }
    }
}

.AddCard {
    margin-top: 10px;
    > input {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        font-size: 16px;
        font-weight: normal;
        line-height: 23px;
        padding: 13px 20px;
        color: black;
        margin-bottom: 10px;
    }
    span {
        color: red;
    }
    #cardNumber {
        width: 100%;
    }
    #expiryDate {
        width: calc(50% - 5px);
        margin-right: 5px;
    }
    #cvc {
        width: calc(50% - 5px);
        margin-left: 5px;
    }
}

.Modal__earlyPaymentHeaderPrice {
    color: $primary;
    font-weight: 700;
}

.Modal__retryPaymentHeaderPrice {
    color: $red;
    font-weight: 700;
}

.CardsDropdown {
    margin-bottom: 20px;
    &.show > .btn-secondary.dropdown-toggle {
        color: black;
    }
    .CardsDropdown__toggleButton {
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        color: black;
        width: 100%;
        padding-right: 40px;
        &:active {
            color: black !important;
        }
        &:after {
            position: absolute;
            right: 15px;
            top: 25px;
        }
        .CardsDropdown__placeholder {
            display: flex;
        }
    }
    .CardsDropdown__menu {
        width: 100%;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.12);
        .dropdown-item {
            padding: 10px 20px;
            display: flex;
        }
    }
}

.FeeTable {
    width: 100%;
    td {
        padding: 0px 0 10px;
    }
    tr:first-child {
        td {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 10px;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
    td:last-child {
        text-align: right;
    }
    .table-footer {
        font-weight: bold;
        color: black;
        td {
            padding: 15px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

input {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.cv li b {
    font-weight: 600;
    text-decoration: underline;
}

input[type="text"] {
    padding: 12px 15px;
}

/* width */

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey;
    border-radius: 4px;
    background: #fff;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #e4e4e4;
    border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #e4e4e4;
}

.dropdown-menu {
    z-index: 9997;
}

.dropdown-header {
    padding: 7px 20px;
    font-weight: bold;
    color: $primary;
    margin-top: 10px;
    &:first-of-type {
        margin-top: 0px;
    }
}

.ToggleGroup {
    overflow: hidden;
    .Toggle {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:last-child {
            border-bottom: none;
        }
    }
}

.Toggle {
    background: white;
    .Toggle__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 30px;
        cursor: pointer;
        h3 {
            flex: 1 0 0%;
            margin-bottom: 0px;
            padding-right: 5px;
            font-size: 18px;
            font-weight: 600;
        }
    }
    a {
        color: $primary;
        transition: none;
        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
    .Toggle__icon {
        font-size: 18px;
    }
    .Toggle__content {
        padding: 0 30px 30px;
        h4 {
            font-size: 16px;
            margin-bottom: 20px;
        }
    }
}

.autoComplete {
    .dropdown-menu {
        min-width: 335px !important;
        li {
            border-bottom: 1px solid #dfe4e8;
            a {
                padding: 14px 21px;
                span {
                    font-size: 16px;
                    color: #353d74;
                    font-size: 16px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                }
            }
        }
    }
}

.IconList__box {
    display: flex;
    flex-direction: row;
    .IconList__icon {
        margin-right: 20px;
    }
}

.IconBoxGroup {
    padding: 65px 0 65px;
    background: white;
    img {
        width: 65px;
        height: 65px;
    }
    .logo {
        width: 65px;
    }
    h2 {
        margin-bottom: 50px;
    }
    h3 {
        margin-top: 24px;
        margin-bottom: 8px;
    }
    p {
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.44;
        color: #47495f;
    }
}

input.form-control {
    line-height: calc(1.5em + 0.75rem + 2px);
}

.form-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

input.form-control:focus {
    color: #495057;
    background-color: #fff;
    border: 2px solid #58a946;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.max-w-640 {
    max-width: 640px;
}

.react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
        width: 100%;
    }
}

button {
    &:hover {
        opacity: 0.8;
    }
}

.phone-number-header {
    a,
    span {
        font-size: 18px;
        color: #fff;
        margin-left: 10px;
        font-weight: 600;
        &:hover {
            color: #fff;
        }
    }
    .fa-phone {
        transform: rotate(90deg);
    }
}

.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.7);
}

.desktop-header {
    &.content-logo {
        .white-logo {
            width: 163px;
        }
    }
    .row {
        .col-auto {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 193px;
            flex: 0 0 193px;
        }
    }
    .phone-number-header {
        padding-top: 10px;
    }
    .links {
        padding: 0;
        padding-top: 10px;
        .nav {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            .nav-item {
                padding: 0 40px;
                border-right: solid 1px rgba(255, 255, 255, 0.8);
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                    border: none;
                }
                a {
                    font-size: 18px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    text-align: right;
                    color: #ffffff;
                    &.active {
                        border-bottom: 2px solid rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
    }
}

.white-logo {
    display: none;
}

.ant-message {
    z-index: 9999 !important;
}

.navbar {
    // z-index:9997;
    // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.18) !important;
    &:after {
        transition: box-shadow 0.2s cubic-bezier(0.35, 0, 0.65, 1) !important;
        background: #ffffff !important;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.18) !important;
        content: "" !important;
        height: 100% !important;
        left: 0px !important;
        position: absolute !important;
        top: 0px !important;
        -webkit-transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1) !important;
        -moz-transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1) !important;
        transition: opacity 0.2s cubic-bezier(0.35, 0, 0.65, 1) !important;
        width: 100% !important;
        z-index: -2 !important;
        opacity: 1 !important;
    }
    &.transparent:after {
        opacity: 0 !important;
    }
}

.navbar {
    .nav-link {
        color: #484848;
        padding: 0 16px !important;
        font-weight: 600;
    }
}

p.success {
    color: $green;
}

/**
 * ==============================================
 * Loading Dots: flashing
 * ==============================================
 */

.LoadingDots {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: white;
    color: white;
    -webkit-animation: dot-flashing 1s infinite linear alternate;
    animation: dot-flashing 1s infinite linear alternate;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    margin: 0 auto;
    &::before,
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }
    &:before {
        left: -13px;
        width: 8px;
        height: 8px;
        border-radius: 5px;
        background-color: white;
        color: white;
        -webkit-animation: dot-flashing 1s infinite alternate;
        animation: dot-flashing 1s infinite alternate;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:after {
        left: 13px;
        width: 8px;
        height: 8px;
        border-radius: 5px;
        background-color: white;
        color: white;
        -webkit-animation: dot-flashing 1s infinite alternate;
        animation: dot-flashing 1s infinite alternate;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }
    &--dark {
        background-color: darkgrey;
        &:before,
        &:after {
            background-color: darkgrey;
        }
    }
}

@-webkit-keyframes dot-flashing {
    0% {
        opacity: 0.1;
    }
    80%,
    100% {
        opacity: 1;
        //   background-color: white;
    }
}

@keyframes dot-flashing {
    0% {
        //   background-color: white;
        opacity: 0.1;
    }
    80%,
    100% {
        opacity: 1;
        //   background-color: white;
    }
}

@media (min-width: 768px) {
    .navbar {
        &.transparent {
            // position: absolute;
            // width: 100%;
            transition-duration: 200ms !important;
            transition-property: background, box-shadow !important;
            transition-timing-function: ease-out !important;
            width: 100% !important;
            box-shadow: none !important;
            border-bottom: none !important;
            // background: linear-gradient(180deg, rgba(0,0,0,0.8), transparent);
            // background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.592) 8.1%, rgba(0, 0, 0, 0.573) 15.5%, rgba(0, 0, 0, 0.537) 22.5%, rgba(0, 0, 0, 0.494) 29%, rgba(0, 0, 0, 0.443) 35.3%, rgba(0, 0, 0, 0.39) 41.2%, rgba(0, 0, 0, 0.33) 47.1%, rgba(0, 0, 0, 0.27) 52.9%, rgba(0, 0, 0, 0.21) 58.8%, rgba(0, 0, 0, 0.157) 64.7%, rgba(0, 0, 0, 0.106) 71%, rgba(0, 0, 0, 0.063) 77.5%, rgba(0, 0, 0, 0.027) 84.5%, rgba(0, 0, 0, 0.008) 91.9%, rgba(0, 0, 0, 0)) !important;
            .white-logo {
                display: inline !important;
            }
            .blue-logo {
                display: none !important;
            }
            .closeIcon {
                color: white !important;
            }
            .nav-link {
                color: white;
            }
        }
    }
}

@media (max-width: 815px) {
    .desktop-header {
        .links {
            .nav {
                .nav-item {
                    padding: 0 20px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.IconBoxGroup {
    padding: 30px 0 65px;
    background: white;
    img {
        width: 65px;
        height: 65px;
    }
    .logo {
        width: 65px;
    }
    h2 {
        margin-bottom: 50px;
    }
    h3 {
        margin-top: 24px;
        margin-bottom: 8px;
    }
    p {
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.44;
        color: #47495f;
    }
}

.header-container {
    display: none;
    &.showAll {
        display: block;
        .phone-number-header {
            img {
                display: none;
            }
        }
        .menu-mobile {
            display: none;
        }
        .links {
            padding: 0;
            display: block;
            .nav {
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                .nav-item {
                    padding: 0 40px;
                    border-right: solid 1px rgba(128, 128, 128, 0.8);
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                        border: none;
                    }
                    a {
                        font-size: 18px;
                        font-weight: 600;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        text-align: right;
                        color: grey;
                        &.active {
                            border-bottom: 2px solid rgba(128, 128, 128, 0.8);
                        }
                    }
                }
            }
        }
    }
    .links {
        display: none;
    }
    .row {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .phone-number-header {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            a,
            span {
                color: grey;
            }
            svg {
                color: grey;
            }
            img {
                margin-left: 20px;
            }
        }
    }
    .menu-mobile {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 100vw;
        background-color: #191b37;
        z-index: 99999999;
        transition: left 0.5s linear;
        -webkit-transition: left 0.5s linear;
        -moz-transition: left 0.5s linear;
        -ms-transition: left 0.5s linear;
        -o-transition: left 0.5s linear;
        &.active {
            left: 0;
        }
        .Header {
            border-bottom: solid 2px #47495f;
            padding: 11px 15px;
            .text-right {
                img {
                    width: 17px;
                }
            }
        }
        .content {
            height: calc(100vh - 67px - 62px);
            padding-top: 17px;
            div {
                text-align: center;
                padding: 20px 0;
                a {
                    font-size: 32px;
                    font-weight: 600;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    text-align: center;
                    color: #19b4d1;
                }
            }
        }
        .footer {
            padding: 17px 15px;
            background: transparent;
            border-top: solid 2px #47495f;
            a {
                font-size: 13px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                color: #5e5f68;
            }
            img {
                margin-left: 8px;
            }
            .divider {
                padding: 0;
                text-align: center;
                position: relative;
                div {
                    position: absolute;
                    top: -13px;
                    width: 1px;
                    display: inline-block;
                    height: 26px;
                    margin: 0;
                    background: #47495f;
                }
            }
        }
    }
}

.confirm-passenger-detail {
    .title {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #47495f;
        margin-bottom: 13px;
    }
    .row {
        margin-bottom: 15px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &:last-child {
            margin-bottom: 36px;
        }
        .check {
            .custom-checkbox {
                .custom-control-label {
                    &::before {
                        left: -1.6rem;
                    }
                    &::after {
                        left: -17px;
                    }
                }
            }
        }
        div {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            color: #47495f;
        }
        .name {
            max-width: calc(100% - 85px - 30px - 54px);
            padding: 0;
            word-break: break-all;
        }
        .date-of-birth {
            -ms-flex-preferred-size: 115px;
            flex-basis: 115px;
        }
    }
}

.Header.hasCountryBanner + .Content {
    padding-top: 70px;
}

.Header:not(.transparent-page) + .Content {
    padding-top: 68px;
}

.Header:not(.transparent-page).contentBanner + .Content {
    padding-top: 123px;
}

.Header:not(.transparent-page).hasCountryBanner + .Content {
    padding-top: 138px;
}

@include media-breakpoint-down(sm) {
    .Content {
        padding-top: 0px;
    }
    .Header:not(.transparent-page) + .Content {
        padding-top: 0px;
    }
    .CountryBanner {
        text-align: left !important;
        padding: 0 15px;
        p {
            font-size: 14px !important;
        }
    }
    .Header.hasCountryBanner + .Content {
        padding-top: 138px;
    }
    .Toggle {
        .Toggle__header {
            h3 {
                font-size: 16px !important;
            }
        }
    }
}

@media (max-width: 681px) {
    .header-container.showAll .links .nav .nav-item {
        padding: 0 10px;
    }
}

@media (max-width: 640px) {
    .header-container {
        &.showAll {
            .phone-number-header {
                img {
                    display: block;
                }
            }
            .links .nav {
                display: none;
            }
            .menu-mobile {
                display: block;
            }
        }
    }
}

@media (max-width: 365px) {
    .header-container {
        .menu-mobile {
            .footer {
                a {
                    font-size: 12px;
                }
                .col-auto {
                    &:first-child {
                        padding-right: 10px;
                    }
                    &:last-child {
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .header-container {
        .row {
            .phone-number-header {
                padding-left: 0;
                a {
                    font-size: 14px;
                }
            }
        }
    }
}

@media (max-width: 705px) {
    .desktop-header {
        .links {
            padding-top: 15px;
            .nav {
                .nav-item {
                    padding: 0 5px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .desktop-header {
        display: none;
    }
    .header-container {
        display: block;
    }
}

@media (max-width: 450px) {
    .react-datepicker-popper {
        -webkit-transform: translate3d(-3px, 44px, 0px) !important;
        transform: translate3d(-3px, 44px, 0px) !important;
        .react-datepicker {
            -webkit-transform: scale(1.16, 1.16);
            transform: scale(1.16, 1.16);
        }
    }
    .custom-date-picker {
        -webkit-transform: translate3d(-10px, 86px, 0px) !important;
        transform: translate3d(-10px, 86px, 0px) !important;
    }
    .review {
        .custom-date-picker {
            -webkit-transform: translate3d(-10px, 39px, 0px) !important;
            transform: translate3d(-10px, 39px, 0px) !important;
        }
    }
}

.MuiDayPicker-monthContainer {
    .Mui-selected {
        background-color: #19b4d1 !important;
    }
}

.rdrMonthAndYearPickers {
    display: none;
}

.rdrDayPassive {
    visibility: hidden;
}

.rdrDayNumber {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
