.login-form {
    input[type="email"],
    input[type="password"],
    input[type="text"] {
        border: 1px solid #bababa;
        border-radius: 0px;
        border-bottom: none;
        font-size: 16px;
        padding: 5px 15px;
        height: auto;

        &::-webkit-input-placeholder,
        &::placeholder {
            color: #bababa !important;
        }

        &:last-of-type {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom: 1px solid #bababa;
        }
        &:first-of-type {
            border-top-left-radius: 8px !important;
            border-top-right-radius: 8px !important;
        }
    }

    .login-form__action-links {
        > div {
            margin-top: 15px;
        }
        a {
            font-weight: 600 !important;
            color: black !important;
            cursor: pointer;
            text-decoration: underline !important;
        }
    }

    button {
        margin-top: 20px;
        background: linear-gradient(98.59deg, #19b4d1 10.67%, #31aec6 97.17%);
        border-radius: 8px;
        color: white;
        font-size: 16px;
        font-weight: normal;
        line-height: 23px;
        border: none;
        padding: 13px;
        transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s,
            transform 0.1s ease 0s !important;

        &:active {
            border: none !important;
            transform: scale(0.96) !important;
        }

        &:focus {
            box-shadow: none !important;
            border: none !important;
        }
    }

    p {
        font-weight: normal;
        font-size: 14px;
    }
}

.login-page {
    // height:100vh;
    // background-color: $bgPrimary;
    padding-top: 108px;
}

.login-wrapper {
    max-width: 400px;
    padding: 0px 20px;
    margin: 0px auto;

    h2 {
        font-weight: 600;
        margin-bottom: 20px;
    }
}
