.footer {
    background-color: #212447;
    padding-top: 78px;
    padding-bottom: 70px;
    .content-text {
        > div {
            margin-bottom: 34px;
            .content {
                border-left: 1px solid #353d74;
                padding-left: 14px;
                height: 100%;
                p {
                    font-size: 18px;
                    font-weight: 300;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.44;

                    color: #ffffff;
                    margin: 0;
                }
                span {
                    font-size: 12px;
                    font-weight: 300;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 2.17;

                    color: #ffffff;
                }
            }
        }
    }
    .more-info {
        margin-top: 55px;
        padding: 22px 0;
        border-top: 1px solid #353d74;
        border-bottom: 1px solid #353d74;
        .row {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .mcafee {
                height: 58px;
            }
            .facebook {
                margin-right: 28px;
            }
            .facebook,
            .instagram {
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media (max-width: 991px) {
    .footer {
        .more-info {
            .row {
                .cards {
                    width: 190px;
                }
                .facebook {
                    margin-right: 18px;
                }
                .mcafee {
                    height: 48px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .footer {
        .more-info {
            .row {
                .cards {
                    width: 190px;
                }
                .facebook {
                    margin-right: 10px;
                    width: 45px;
                }
                .tiwtter {
                    width: 45px;
                }
                .mcafee {
                    height: 40px;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .footer {
        padding-top: 38px;
        padding-bottom: 35px;
        .more-info {
            margin-top: 21px;
            border-bottom: none;
            .row {
                .cards {
                    width: 286px;
                }
                .facebook {
                    margin-right: 18px;
                    width: auto;
                }
                .tiwtter {
                    width: auto;
                }
                .mcafee {
                    height: 58px;
                }
                > div {
                    margin-bottom: 34px;
                    &:first-child {
                        text-align: center;
                    }
                    &:last-child {
                        text-align: center !important;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
