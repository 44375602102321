.search-result-box__flight-details {
    background: #f6f7f8;
    padding: 23px;
    border-top: 1px solid rgb(221, 221, 221);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    // -webkit-box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
    // box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);

    .am-pm {
        font-size: 14px;
        font-weight: normal;
        margin-left: 1px;
        margin-right: 0px !important;
    }

    .leg__heading {
        margin-bottom: 10px;
        font-size: 16px;

        .leg__heading__title {
            font-weight: 600;
            color: black;
            margin-right: 10px;
        }

        .leg__heading__departure {
            font-size: 14px;
            color: #9a9494;
        }
    }

    .leg__details {
        // border-radius:4px;
        // -webkit-box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);
        // box-shadow: 0 0 2px 0 rgba(19,26,31,0.12),0 2px 4px 0 rgba(19,26,31,0.22);

        -webkit-box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.08) 0px 3px 6px !important;
        border-radius: 8px !important;

        background: white;
        padding: 20px 11px;
        margin-bottom: 15px;

        .leg__subflight {
            margin-bottom: 20px;

            .logo-flight {
                max-height: 20px;
                max-width: 60px;
            }
            .leg__subflight__heading {
                font-size: 12px;
                color: #adadad;
                vertical-align: middle;
                margin-bottom: 15px;

                span {
                    margin-left: 8px;
                }
            }

            .leg__subflight__timeline {
                display: flex;
                align-items: center;

                > div {
                    display: inline-block;
                }

                .leg__subflight__duration {
                    width: 4rem;
                    font-size: 12px;
                    text-align: center;
                }

                .leg__subflight__segments {
                    display: flex;
                    min-height: 3.75rem;
                    flex-grow: 1;

                    .leg__subflight__segments-routes {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        flex-grow: 2;

                        > div {
                            color: #353d74;
                            font-size: 1rem;
                            line-height: 1.5rem;
                            font-weight: 600;

                            span {
                                margin-right: 20px;
                            }
                        }
                    }
                }

                .leg__subflight__circles {
                    display: flex;
                    padding-top: 0.375rem;
                    padding-right: 1.125rem;
                    padding-bottom: 0.375rem;
                    flex-direction: column;
                    justify-content: space-between;

                    &:after,
                    &:before {
                        content: "";
                        display: block;
                        // z-index: 2;
                        width: 0.625rem;
                        height: 0.625rem;
                        border: 0.125rem solid #b2b2bf;
                        border-radius: 100%;
                        background: #fff;
                    }
                }
                .circles-track {
                    position: relative;
                    left: 0.25rem;
                    display: flex;
                    flex-grow: 1;
                    &:after {
                        position: relative;
                        content: "";
                        width: 0.125rem;
                        background-color: #b2b2bf;
                    }
                }
            }

            .leg__stopover {
                margin-top: 10px;
                background: rgba(25, 180, 209, 0.11);
                border-radius: 4px;
                color: #19b4d1;
                padding-left: 5px;
                margin-left: -5px;
                padding-right: 5px;
                margin-right: -5px;
                padding-top: 10px;
                padding-bottom: 10px;

                .leg__stopover__message {
                    font-weight: 600;
                    font-size: 0.9rem;
                    line-height: 1.5rem;
                    margin-left: 28px;
                }
            }
        }

        .leg__meta-info {
            padding-left: 0px;
            margin: 10px 0px 0px;
            font-size: 12px;

            li {
                margin-right: 20px;
                display: inline-block;
                list-style-type: none;
                position: relative;

                &:after {
                    content: "";
                    width: 2px;
                    height: 2px;
                    border-radius: 100%;
                    position: absolute;
                    // z-index:1;
                    right: -12px;
                    top: 7px;
                    opacity: 0.9;
                    background: grey;
                }

                &:last-child:after {
                    content: none;
                }

                strong {
                    color: #505050;
                    font-weight: 600;
                }
            }
        }
    }
}
